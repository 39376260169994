window.addEventListener('load', function(event) {
  /* Fetch all lazy loading windows. Prepare for TurboStreams */
  const items= document.querySelectorAll('[data-zv-load-url]');
  items.forEach( function (elm) {
    var placeholderTimeout = setTimeout(function() {
      elm.innerHTML = "<div class='placeholders show'><div class='placeholder'><span class='flex-grow'>...</span><span class='icon'>?</span></div><div class='placeholder'><span class='flex-grow'>...</span><span class='icon'>?</span></div><div class='placeholder'><span class='flex-grow'>...</span><span class='icon'>?</span></div><div class='placeholder'><span class='flex-grow'>...</span><span class='icon'>?</span></div></div>"
    }, 500);

    Rails.ajax({
      type: 'get',
      url: elm.dataset.zvLoadUrl,
      dataType: 'script',
      success: function(data) {
        clearTimeout(placeholderTimeout);
        elm.innerHTML= data.html;
        activateJS(elm);
        // throw success event (reacted upon by other controls)
        var customEvent = new CustomEvent('custom::ajax:success', {
          detail: {
            target: elm
          }});
        document.dispatchEvent(customEvent);
      }
    })
  })

  window.activateJS(document);

  // Date range datepicker fields (from - to)
  var from_elems = $("input[data-date-range='true'].date_picker");
  from_elems.on('change', function (evt) {
    datepicker_from_to($(this));
  });
});

/* Function where all initial event bindings occur. All bindings need to occur on load of new document and on load of Ajax partial */
var initDocument=function(doc) {
  /** When data-skip-validations on Submit buttons is true then remove form validation before executing. Handy on 'cancel' submit buttons */
  $(":submit[data-skip-validations='true']").click(function(event) {
    $(this).closest('form').attr('novalidate', 'novalidate');
  });

  /** Enable javascript only elements */
  $(".jsonly", doc).show();

  /** Hide elements that are optionally shown with javascript. Without Javascript these elements will always be visible */
  $(".jshide", doc).hide();

  /** Speciale forms (filter weergave) direct met javascript submitten. */
  $('form.js_submit select, form.js_submit :radio, form.js_submit :checkbox', doc).change(function(event) {
    // Use this construction to support remote forms via UJS
    const form= $(event.currentTarget).closest('form')[0];
    return submit_remote_form(form, event);
  });

  /** Forms zonder direct submit, maar wel met enkele onderdelen zoals (filter weergave) direct met javascript submitten. */
  $('form select.js_submit, form .js_submit:radio, form .js_submit:checkbox', doc).change(function(event) {
    // Use this construction to support remote forms via UJS
    const form= $(event.currentTarget).closest('form')[0];
    return submit_remote_form(form, event);
  });

  /* In Chrome en Safari werkt een Enter niet in de zoekformulieren van Lijsten indien zich meerdere input fields type 'text' bevinden en deze verborgen kunnen worden. Help deze een handje met JS */
  $("#search_form input", doc).change( function(event) {
    if ( $("#search_form2").is(':hidden')) {
      $(event.currentTarget).closest('form').submit();
      return false;
    }
  });

  // Flash Close button
  $(".flash img.close", doc).click( function() {
    jQuery(this).parent().remove();
  });

  // A form can contain an ASIDE.patient_info Element to inform the user on a submit of what's happening.
  // On initial (re)display (validation) hide this element
  $('aside.patience_info', doc).hide();
  // On click of submit button, show this info Element. It will disappear automatically on the new request
  $("form input[type='submit'].patience_info, a.patience_info", doc).click( function() {$('aside.patience_info').show();});

  /* General form solution to copy/duplicate field data to another field on change
    Specify unique element ID of duplicate INPUT field in data attribute on-change-copy-to
  */
  $('[data-on-change-copy-to]').change(function(event) {
    const copy_to_element= $(this).data('on-change-copy-to');
    if ($(copy_to_element).val()=='') {
      $(copy_to_element).val($(this).val());
    }
  });

  $('a.toggle_active').click(function(event) {
    toggle_element(event.target);
  });

  $('nav.dropdown').click(function(event) {
    $(this).toggleClass('active');
  });
}


var activateJS= function(doc) {
  window.initAutocomplete(doc);
  initDocument(doc);
  window.bind_conditionals(doc);
  window.tb_initDocument(doc);
}

window.activateJS = activateJS;

window.initDocument = initDocument

document.addEventListener("click", function(event) {
  var targetId= event.target.dataset.onclickReenter
  if (targetId) {
    // Re-enter a field defined by targetId by blanking it and giving it focus.
    const elm= document.querySelector(targetId)
    if (elm) {
      elm.value= null;
      elm.focus();
    }
  } else if (event.target.dataset.onclickReload) {
    // Reload the page when data-onclick-reload=true
    window.location.reload();
  } else if (event.target.dataset.onclickPrint) {
    // Print the page when data-onclick-print=true
    window.print();
    event.preventDefault();
    event.stopPropagation();
  }
});


function toggle_element(elm) {
  var toggleIds= $(elm).data('toggle-element-id').split(',');
  for( let i=0; i < toggleIds.length; i++) {
    const toggleId= "#" + toggleIds[i];
    if ($(toggleId).is(':hidden')) {
      $(elm).addClass('active');
      $(toggleId).show();
    } else {
      $(elm).removeClass('active');
      $(toggleId).hide();
    }
  }
}
window.toggle_element = toggle_element;

// Toggle all required attributes on an element
var toggle_required = function(elm, required) {
  elm.setAttribute('aria-required', required);
  elm.required= required;

  if (required) {
    elm.classList.add('required');
  } else {
    elm.classList.remove('required');
  }
}
window.toggle_required = toggle_required;

/* Function used with nested_attributes fields_for form helper */
var replace_ids = function(s){
  var new_id = new Date().getTime();
  return s.replace(/NEW_RECORD/g, new_id);
}
window.replace_ids = replace_ids;

/* Get the column index of the given TD taking colspan into account */
var getElementColumn= function(td) {
  var col = 0;
  $(td).prevAll('td, th').each(function() {
      col += +$(this).attr('colspan') || 1;
  });
  return col;
}

window.getElementColumn = getElementColumn;

/* Get the COL element of a table */
var getCol= function(table, colNumber) {
  var col = 0, elem;
  $(table).find('> colgroup > col').each(function() {
      if (colNumber == col) {
          elem = this;
          return false;
      }
      col += +$(this).attr('span') || 1;
  });
  return elem;
}

window.getCol = getCol;

/* PadLeft function for numbers */
function padl( num, size ) {
  return ( Math.pow( 10, size ) + ~~num ).toString().substring( 1 );
}

window.padl = padl;

/* Determine wether we run within an iFrame */
function within_iframe() {
  return window.location != window.parent.location;
}

window.within_iframe = within_iframe;

function submit_remote_form(form, event) {
  if (form.dataset['remote']=='true') {
    // Works with non-remote forms on Firefox too
    Rails.handleRemote.call(form, event);
  } else {
    // Needed for Chrome/Safari for non-remote forms
    form.submit();
  }
  return false;
}

window.submit_remote_form = submit_remote_form;

/**
 * Set value for one or more elements
 * @param elements [String] CSS selectors, comma-separated
 * @param value [String]
 */
function set_value (elements, value) {
  const list=document.querySelectorAll(elements);
  for (var i=0; i<list.length; i++) {
    list[i].value=value;
  }
}

window.set_value = set_value;

/**
 * Check if value of element is valid Dutch postal code
 * @param el element
 * @returns {boolean}
 */
function dutch_postal_code (el) {
  return el.value.search(/^\d{4}\s?[A-Z]{2}/i)!=-1;
}

window.dutch_postal_code = dutch_postal_code;

/* Polyfill for Internet Explorer 11. See: https://developer.mozilla.org/nl/docs/Web/API/Element/matches */
if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector ||
                              Element.prototype.webkitMatchesSelector;
}


/**
* Absence date picker - change from and to input fields and date picker contents
* @param from [jQuery element] from-date input
*/
function datepicker_from_to(from){
  var to = from.siblings('input');
  to.val('');
  if (from.val() != '') {
    to.datepicker("destroy");
    var to_date = from.val().split("-");
    to.datepicker({ dateFormat: 'dd-mm-yy', minDate: new Date(to_date[2],to_date[1]-1, parseInt(to_date[0])+1) });
    to.datepicker('refresh');
  }
}

window.datepicker_from_to = datepicker_from_to;