import CookieStorage from "../polyfills/cookie";

(function(){
  const theme =CookieStorage.getCookie('theme')
  if (theme){
    document.body.classList.add(theme);
  }
  
  var themeSwitch;
  window.addEventListener("load", function(event) {
    document.removeEventListener('custom::ajax:success', init)
    document.addEventListener('custom::ajax:success', init)
    init()
  });

  function init(){
    themeSwitch = document.getElementById('theme_select');

    const theme =CookieStorage.getCookie('theme')
    if (theme){
      document.body.classList.add(theme);
      if (themeSwitch){
        themeSwitch.value = theme;
      }
    }
    if (themeSwitch){
      themeSwitch.removeEventListener('change', changeTheme);
      themeSwitch.addEventListener('change', changeTheme);
    }
  }

  function changeTheme(event){
    const theme = event.currentTarget.value;
    CookieStorage.setCookie('theme', theme);
    themeSwitch.querySelectorAll('option').forEach(function(option){
      document.body.classList.remove(option.value);
    });
    
    window.setTimeout(function(){
      location.reload();
      // window.location.href = window.location.href;
    }, 100);
  }
})();