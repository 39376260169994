
var steps = (function(){
  window.addEventListener("load", function(event) {
    document.removeEventListener('custom::ajax:success', init)
    document.addEventListener('custom::ajax:success', init)
    init();
  });

  function init(){
    document.querySelectorAll('[data-step-toggler]').forEach(function(element){
      element.addEventListener('click', setStep)
    })
  }

  function setStep(event) {
    event.stopPropagation();
    event.preventDefault();
    const oldStep = event.currentTarget.closest('[data-step]').dataset.step;
    const step = event.currentTarget.dataset.stepToggler
    const visibleStep = document.querySelector('[data-step="' + step + '"]')

    var customEvent = new CustomEvent('custom::navigation:change', {
      detail: {
        step: step,
        oldStep: oldStep
      }, cancelable: true});
    document.dispatchEvent(customEvent);

    if (!customEvent.defaultPrevented){
      document.querySelectorAll('[data-step]').forEach(function(element){
        element.classList.add('hidden')
      })

      visibleStep.classList.remove('hidden')
    }
   }

   return {
    init: init
   }
})()
