(function(){
  window.addEventListener("load", function(event) {
    document.removeEventListener('custom::ajax:success', init)
    document.addEventListener('custom::ajax:success', init)
    init();
  });

  function init(){
    let elements = document.querySelectorAll('[data-mark-checkboxes]')
    Array.from(elements).forEach(function(element) {
      element.removeEventListener('click', handleCheckboxes)
      element.addEventListener('click', handleCheckboxes)
    })

    elements = document.querySelectorAll('[data-unmark-checkboxes]')
    Array.from(elements).forEach(function(element) {
      element.removeEventListener('click', handleCheckboxes)
      element.addEventListener('click', handleCheckboxes)
    })
  }

  function handleCheckboxes(event){
    var target = event.target;
    if (target.dataset.markCheckboxes) {
      const query = target.dataset.markCheckboxes;
      const checkboxList = document.querySelector(query);

      const items = checkboxList.querySelectorAll('[type=checkbox]:enabled');
      items.forEach( function (elm) {
        // Only visible elements
        if (!(window.getComputedStyle(elm).display === "none")) {
          elm.checked = !elm.checked;
          if (elm.checked) {
            $(elm).closest('label').addClass('checked');
          } else {
            $(elm).closest('label').removeClass('checked');
          }
        }
      });
    } else if (target.dataset.unmarkCheckboxes){
      const query = target.dataset.unmarkCheckboxes;
      const checkboxList = document.querySelector(query);

      const items = checkboxList.querySelectorAll('[type=checkbox]:enabled');
      items.forEach( function (elm) {
        // Only visible elements
        if (!(window.getComputedStyle(elm).display === "none")) {
          elm.checked = false;
          if (elm.checked) {
            $(elm).closest('label').addClass('checked');
          } else {
            $(elm).closest('label').removeClass('checked');
          }
        }
      });
    }
  }
})();