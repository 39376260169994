var CGMStorage = (function(){

  var tmpStorage = {}

  function getItem(key) {
    try {
      if (localStorage) {
        return localStorage.getItem(key)
      } else {
        return getFromTmp(key);
      }
    } catch (ex) {
      return getFromTmp(key);
    }
  }

  function getFromTmp(key) {
    return tmpStorage[key] == undefined ? null : tmpStorage[key]
  }

  function setItem(key, flag) {
    storeItem(key, flag);
  }

  function storeObject(key, data) {
    storeItem(key, JSON.stringify(data));
  }

  function storeItem(key, data) {
    try {
      if (localStorage) {
        localStorage.setItem(key, data);
      } else {
        tmpStorage[key] = data;
      }
    } catch(ex) {
      tmpStorage[key] = data;
    }
  }

  function setCookie(name,value,days) {
    var expires = "";

    var date = new Date();
    date.setTime(date.getTime() + (1000*24*60*60*1000));
    expires = "; expires=" + date.toUTCString();
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }

  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }

  return {
    getItem: getItem,
    setItem: setItem,
    storeObject: storeObject
  }
})();

window.CGMStorage = CGMStorage;
export default CGMStorage;