document.addEventListener("click", function(event) {

  if (event.target.dataset.onclickKeepProfessionalEmail) {
    // From dialog when entered duplicate e-mail address for professional. If button 'Keep e-mail' pressed then fill out the username
    fill_username();
  }
});

// Fill username from email
function fill_username() {
  let username = $('input#professional_username');
  let email = '';
  if ($('input#professional_email').length) {
    email = $('input#professional_email').val();
  } else {
    email = $('div.form-group.row:contains("e-mail") div.form-control-plaintext').text();
  };
  if (username.val() == '') {
    username.val(email);
  };
  username.focus();
}