window.addEventListener('load', function(event) {

  $(document).on('blur', '#team_care_mailbox', function (evt) {
    if ($('#team_care_code, #team_care_mailbox').val()=='') {
      $('.team_care_code').removeClass('hide');
    }
  });

  $(document).on('click', '#edit_care_mailbox_btn', function (evt) {
    $('.team_care_code').removeClass('hide');
    window.set_value('#team_care_code,#team_care_mailbox', '');
  });

});