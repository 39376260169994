window.addEventListener("load", function(event) {

  /* Interval refresh for client post */
  var container= document.querySelector('#client_comments[data-last-comment-id]')
  if (container) {
    // Timeout defaults to 30 seconds
    var postRefresh= setInterval(refresh_client_post, container.dataset.timeout || 30000);
  }
});

var newMessageElement = document.getElementById('new_comment');
if (newMessageElement){
  var fileInput = newMessageElement.querySelector('input[type="file"]')
  fileInput.removeEventListener('change', onNewMessage)
  fileInput.addEventListener('change',onNewMessage)
}

function onNewMessage(event){
  const container= document.querySelector('#client_comments')
  if (container) {
    var fileInput = event.currentTarget;
    if (fileInput && fileInput.value) {
      container.dataset.processing = 'true';
    }
    else {
      container.dataset.processing = 'false';
    }
  }
}

window.addEventListener("ajax:beforeSend", function(event){
  switch(event.target.id) {
    case 'new_client_comment':
      {
        const container= document.querySelector('#client_comments')
        if (container) {
        var fileInput = event.target.querySelector('input[type="file"]')
        if (fileInput && fileInput.value) {
          container.dataset.processing = 'true';
        }
        else {
          container.dataset.processing = 'false';
        }
      }
    }
  }
})

/* Everything on client posts and care plans */
window.addEventListener("ajax:success", function(event) {
  // var [data, status, xhr] = event.detail;
  var data = event.detail[0];
  var status = event.detail[1];
  var xhr = event.detail[2];

  let container = null;

  switch(event.target.id) {
    case 'new_client_comment':

      if (data.last_comment_id) {
        container = document.querySelector('#client_comments[data-last-comment-id]')
        if (container) {
          container.dataset.lastCommentId= data.last_comment_id
        }
      }

      if (data.index_html) {
        container= document.querySelector('#client_comments')
        if (container) {
          container.insertAdjacentHTML( 'beforeend', data.index_html );
        }
      }

      if (data.form_html) {
        container= document.querySelector('#new_comment')
        if (container) {
          container.innerHTML= data.form_html;
        }
      }
      event.stopPropagation();
      event.preventDefault();
      break;

    case 'client_post_edit_name_btn':
      /* Client post request Edit name button */
      container= document.querySelector('#client_post_name')
      if (container) {
        container.innerHTML= data.html;
      }

      event.stopPropagation();
      event.preventDefault();
      break;

    case 'client_post_edit_name_form':
      /* Edit name form submitted */
      if (data.reload==true) {
        location.reload();
      } else {
        container= document.querySelector('#client_post_name')
        if (container) {
          container.innerHTML= data.html;
        }
      }

      event.stopPropagation();
      event.preventDefault();
      break;

    case 'edit_actors_btn':
      container = container= document.querySelector('#actors')
      if (container) {
        container.querySelector('[data-content]').innerHTML= data.html;
        event.target.classList.add('hide');
        $(event.target).tooltip('hide');
      }

      event.stopPropagation();
      event.preventDefault();
      break;

    case 'client_post_search_form':
      container= document.querySelector('#client_posts_list_container')
      if (container) {
        container.innerHTML= data.html;
      }
      event.stopPropagation();
      event.preventDefault();
      break;

    case 'care_plan_edit_btn':
      container= document.querySelector('#care_plan_info')
      if (container) {
        container.innerHTML= data.html;
      }

      event.stopPropagation();
      event.preventDefault();
      break;

    case 'care_plan_form':
      if (data.reload==true) {
        location.reload();
      } else {
        container= document.querySelector('#care_plan_info')
        if (container) {
          container.innerHTML= data.html;
        }
      }

      event.stopPropagation();
      event.preventDefault();
      break;

    case 'edit_selection_btn':
      container= document.querySelector('.master-detail > .detail')
      if (container) {
        $(event.target).tooltip('hide');

        let list_container= container.querySelector('[data-content]');
        list_container.innerHTML= data.html;
        window.tb_initDocument(list_container);    // Initialize JS functions like Tooltips

        if (window.matchMedia("(max-width: 1080px)").matches) {
          // Hide FreshChat button on mobile and tablet till 1080px
          const freshChatFrame= document.querySelector('#fc_frame')
          if (freshChatFrame) freshChatFrame.remove();
        }
      }

      event.stopPropagation();
      event.preventDefault();
      break;

    default:

      if (event.target.classList.contains('destroy_request_btn')) {
        /* destroy_request_btn clicked, show dialog */
        container= document.querySelector('#js_popup')
        if (container) {
          container.innerHTML= data.html;
          $('#delete_confirmation_dlg').modal('show');
        }
      }
  }

  var customEvent = new CustomEvent('custom::ajax:success', {
    detail: {
    }});
  document.dispatchEvent(customEvent);
});

window.addEventListener("change", function(event) {
  // If any actor checkbox was checked then re-enable the Submitbutton if it was disabled by the JS validation #no_recipients_error_notification
  if (event.target.type=='checkbox' && event.target.name.match(/^client_post\[actors_attributes\]/)) {
    const btn= document.querySelector('#client_post_submit_btn')
    if (btn) {
      btn.disabled= false;
    }
  }
})

window.addEventListener("click", function(event) {
  var elm= event.target;

  if (elm.type=='button' && elm.dataset.bsToggleTrigger=='#search-expand') {
    // When Search button pressed, set focus on Search INPUT field
    const search_input= document.querySelector('#search_term')
    if (search_input) {
      search_input.focus();
    }
  }
})

window.addEventListener('submit', function (event) {
  switch(event.target.id) {
    case 'new_client_post':
      // New client post form, check recipients
      if (event.target.querySelectorAll('input[value][type="checkbox"][name^="client_post[actors_attributes]"]:checked').length==0) {
        // No recipients checked.
        const elm= event.target.querySelector('#no_recipients_error_notification')
        if (elm) {
          // Show hidden error alert
          elm.classList.remove('hide');
        }
        const btn= event.target.querySelector('input[type=submit]')
        if (btn) {
          // Re-enable Submit button (does not work at this position...., see onChange solution above)
          btn.disabled=false;
        }
        event.preventDefault();
      }
  }
})


function refresh_client_post() {
  const elm = document.querySelector('#client_comments');
  if (!elm) { return; }

  // If Time-out modal dialog is present, no refresh!
  const timeoutIsActive= document.querySelector('body[data-timeout="true"]');
  if (timeoutIsActive) { return; }

  Rails.ajax({
    type: 'get',
    url: elm.dataset.url,
    dataType: 'script',
    data: new URLSearchParams({'since_id': elm.dataset.lastCommentId}).toString(),
    success: function(data) {
      if (data.html==null) return;
      const container= document.querySelector('#client_comments')
      if (container) {
        // TODO does this work with deleting items

        var wasProcessing = container.dataset.processing || 'false';
        container.dataset.processing = data.processing;

        if (data.processing) { return; }

        if (container.dataset.lastCommentId == data.last_comment_id && wasProcessing == data.processing + '') { return; }

        container.innerHTML= data.html;
        container.dataset.lastCommentId= data.last_comment_id;

        if (data.newly_updated_comments && data.newly_updated_comments.length > 0) {
          const comments=document.querySelectorAll(data.newly_updated_comments.join());
          comments.forEach( function (elm) {
            elm.classList.add('updated');
          })

          // Find the first element given 'updated' class
          const first_child = container.querySelector('.updated')
          if (first_child) {
            // and insert Info element
            let alertElm= createElementFromHTML(data.info_html);
            container.insertBefore(alertElm, first_child);
          }
        }

        var customEvent = new CustomEvent('custom::ajax:success', {
          detail: {
          }});
        document.dispatchEvent(customEvent);
      }
    }
  })
}

window.refresh_client_post = refresh_client_post

/* Use this for compatibility with IE 11 */
function createElementFromHTML(htmlString) {
  var div = document.createElement('div');
  div.innerHTML = htmlString.trim();

  // Change this to div.childNodes to support multiple top-level nodes
  return div.firstChild;
}