window.Theme = (function(){
  function createSnowflake() {
    // if (!document.querySelector('[data-theme="christmas"]')){
    //   return;
    // }

    const snowflake = document.createElement('div');
    snowflake.classList.add('snowflake');
    snowflake.textContent = '•';
    snowflake.style.fontSize = Math.random() * 24 + 20 + 'px';
    snowflake.style.left = Math.random() * window.innerWidth + 'px';
    snowflake.style.animation = `fall ${Math.random() * 4 + 4}s linear infinite, sideWays ${Math.random() * 2 + 1}s ease-in-out infinite`;

    document.body.appendChild(snowflake);

    setTimeout(() => {
      snowflake.remove();
    }, Math.random() * 3000 + 4000);
  }

  function createEgg(){
    const egg = document.createElement('div');
    egg.classList.add('egg');
    egg.style.left = Math.random() * window.innerWidth + 'px';
    egg.style.top = Math.random() * window.innerHeight + 'px';
    egg.style.position = 'fixed';
    egg.style.zIndex = 1000000;
    egg.style.pointerEvents = 'none';

    // Random background color
    const colors = ['#eb639c', '#ffcd70', '#8afeac', '#a2fdbc', '#b2a3ff'];
    egg.style.backgroundColor = colors[Math.floor(Math.random() * colors.length)];

    // Random size
    const size = Math.floor(Math.random() * 40 + 20); // 20px to 60px
    egg.style.width = `${size}px`;
    egg.style.height = `${size * 1.3}px`;
    egg.style.borderRadius = '50%';

    // Random rotation
    const rotation = Math.floor(Math.random() * 360);
    egg.style.transform = `rotate(${rotation}deg)`;

    document.body.appendChild(egg);

    setTimeout(() => {
      egg.remove();
      createEgg();
    }, Math.random() * 3000 + 1000);
  }

  function init(){;
    updateSeasonalLogo();
  }


  function updateSeasonalLogo() {
    const logo = document.getElementById('theme-logo');
    if (!logo) return;

    const now = new Date();
    const year = now.getFullYear();

    const isChristmas = now >= new Date(year, 11, 5) && now <= new Date(year, 11, 31);
    const isEaster = now >= new Date(year, 3, 1) && now <= new Date(year, 3, 21);
    const isSpring = now >= new Date(year, 2, 20) && now <= new Date(year, 5, 21);
    const isWinter = now >= new Date(year, 11, 21) && now <= new Date(year, 2, 19);

    if (isChristmas) {
      logo.src = logo.dataset.logoChristmas;
      setInterval(createSnowflake, 150)
      document.body.classList.add('christmas')
    } else if (isEaster) {
      logo.src = logo.dataset.logoEaster;
      document.body.classList.add('easter')
      createEgg()
    } else if (isSpring) {
      logo.src = logo.dataset.logoSpring;
      document.body.classList.add('spring')
    } else if (isWinter){
      setInterval(createSnowflake, 150)
      document.body.classList.add('winter')
    }
  }

  return {
    init: init
  }

})();

window.addEventListener("load", function(event) {
  if (document.body.classList.contains('season')){
    window.Theme.init();
  }
});