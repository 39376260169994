window.addEventListener('load', function(event) {

  // Click download button if it exists to download Excel file
  const btn= document.getElementById('reports_download')
  if (btn) {
    btn.click();
    btn.remove();
  }

  const btn2= document.getElementById('pdf_download')
  if (btn2) {
    btn2.click();
    btn2.remove();
  }
});