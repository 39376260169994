(function(){
  window.addEventListener("load", function(event) {
    document.removeEventListener('custom::ajax:success', init)
    document.addEventListener('custom::ajax:success', init)
    init();
  });
  
  function init(){
    var showWizardElement = document.querySelector('[data-wizard]')
    if (!showWizardElement) { return }
    
    if (!document.body.classList.contains('mobile')) { return }
    
    document.body.classList.add('wizard--showing')
    
    const elements1 = document.querySelectorAll('[data-no-step]');
    elements1.forEach(function (element) {
      element.style.display = 'none'
    });
    
    const elements2 = document.querySelectorAll('[data-step]');
    elements2.forEach(function (element) {
      var next = element.querySelector('[data-wizard-next]')
      if (next) {
        next.removeEventListener('click', onNextClicked)
        next.addEventListener('click', onNextClicked)
      }
      
      var prev = element.querySelector('[data-wizard-prev]')
      if (prev) {
        prev.removeEventListener('click', onBackClicked)
        prev.addEventListener('click', onBackClicked)
      }
      
      var submitElement = element.querySelector('button[type="submit"]')
      if (submitElement) {
        submitElement.removeEventListener('click', onSubmitClicked)
        submitElement.addEventListener('click', onSubmitClicked)
      }
    });

    showStep(1);
  }
  
  function showStep(step){
    const elements = document.querySelectorAll('[data-step]');
    elements.forEach(function (element) {
      var steps = element.dataset.step.split(',');
      if (steps.includes(step + '')){
        element.style.display = 'block'
      } else {
        element.style.display = 'none'
      }
    });
  }
  
  function onNextClicked(event){
    var target = event.currentTarget;
    var stepElement = target.closest('[data-step]')
    if (stepElement){
      var step = parseInt(stepElement.dataset.step);
      showStep(step + 1);
    }
  }

  function onBackClicked(event){
    var target = event.currentTarget;
    var stepElement = target.closest('[data-step]')
    if (stepElement){
      var step = parseInt(stepElement.dataset.step);
      if (step <= 1) { step = 2; }
      showStep(step + - 1);
    }
  }
  
  function onSubmitClicked(event){
    var target = event.currentTarget;
    target.form.submit();
  }
})();