/* Edit access */
var person_edit = function(detail, form_window) {
  const data = detail[0];

  // Editable window:
  form_window.innerHTML= data.html;
  window.tb_initDocument(form_window);
  form_window.querySelector('[aria-invalid="true"]').focus();        // Focus on first field with errors
}

/* Updated person */
var person_updated = function(detail, form_window) {
  const data = detail[0];

  if (data.saved) {
    location= data.location;
  } else {
    // Toon form opnieuw
    person_edit(event.detail, form_window);
  }
}

window.person_updated = person_updated