window.addEventListener('load', function(event) {
	/* List of checkboxes where the very first means select ALL. So if the first is cheched, uncheck the rest and vice versa. */
	$('#mail_message_target_auth_profile_ids_0').change(function(event) {
		if (this.checked) {
			$('div.mail_message_target_auth_profile_ids :checkbox').not(this).attr('checked', false);
		}
	});
	$('div.mail_message_target_auth_profile_ids :checkbox').not('#mail_message_target_auth_profile_ids_0').change(function(event) {
		if (this.checked) {
			$('#mail_message_target_auth_profile_ids_0').attr('checked', false);
		}
	})
});