/* FreshChat helpdesk Chat application */
$(document).ready(function() {
  // do not initialize freschat on app
  if (window.isApp) { return;}
  
  if (window.fcWidget) {
    initFreshChat();
    setUserProperties();
  }
});

function initFreshChat() {
  window.fcWidget.init({
    token: "89de7861-1a35-4cd5-92d4-c65af78e3c63",
    host: 'https://ozoverbindzorg-help.freshchat.com',
    externalId: $('meta[name="fc_id"]').attr('content'),
    config: {
      content: {
        headers: {
          csat_question: 'Hebben we jou voldoende geholpen?'
        }
      }
    }
  });
}

function setUserProperties() {
  window.fcWidget.user.setProperties({
    username: $('meta[name="fc_username"]').attr('content'),
    email: $('meta[name="fc_email"]').attr('content'),
    name: $('meta[name="fc_name"]').attr('content')
  });
}