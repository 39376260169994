
var state = (function(){

  window.addEventListener("load", function(event) {
    document.removeEventListener('custom::ajax:success', init)
    document.addEventListener('custom::ajax:success', init)
    init();
  });

  function init(){
    document.querySelectorAll('[data-checkbox-unique]').forEach(x => {
      x.querySelectorAll('input[type="checkbox"]').forEach( xx => xx.addEventListener('change', checkUnique))
    })
  }

  function checkUnique(event) {
    event.stopPropagation();
    event.preventDefault();
    const target = event.currentTarget;
    if (target.checked){
      document.getElementById('appointment_appointment_set_attributes_selected_appointment_date_id').value = target.value
      
      document.querySelectorAll('[data-checkbox-unique]').forEach(x => {
        x.querySelectorAll('input[type="checkbox"]').forEach( xx => {
          if (xx != target){
            xx.checked = false
          }
        })
      })
    } 
  }

  return {
    init: init
  }
})()

