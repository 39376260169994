window.addEventListener('load', function(event) {
  $(".proto_statements .dblclick").dblclick(function(event) {
    /** On double click, redirect to module */
    const click_url= event.target.parentElement.dataset.modulePath;
    if (click_url) {
      // Redirect to new location
      location.href=click_url;
    }
    return false;
  });

	onloadProtoStatements();
});

// onLoad Document
function onloadProtoStatements() {
  const current_stmt= document.querySelector('#proto_statement_statement');
  if (current_stmt) {
    onchangeProtoStatementName(current_stmt.value);

    $(current_stmt).change(function(event) {
      onchangeProtoStatementName(event.target.value);
    });
  }
}

function onchangeProtoStatementName(value) {
  // Attribuut
  toggle_statement_field('#proto_statement_proto_attribute_id_wrapper', window.ps_display_attribute_conditions, value);
  // Variabele naam
  toggle_statement_field('#proto_statement_var_name_wrapper', window.ps_display_var_name_conditions, value);
  // Operator
  toggle_statement_field('#proto_statement_operator_wrapper', window.ps_display_operator_conditions, value);
  // Expressie
  toggle_statement_field('#proto_statement_expression_wrapper', window.ps_display_expression_conditions, value);
  // Button text
  toggle_statement_field('#proto_statement_button_text_wrapper', window.ps_display_button_text_conditions, value);
  // Format conditions
  toggle_statement_field('#proto_statement_display_format_wrapper', window.ps_display_display_format_conditions, value);
  // Show Text
  toggle_statement_field('#proto_statement_show_text_wrapper', window.ps_display_show_text_conditions, value);
  // Get Text
  toggle_statement_field('#proto_statement_get_text_wrapper', window.ps_display_get_text_conditions, value);
  // Set Text
  toggle_statement_field('#proto_statement_set_text_wrapper', window.ps_display_set_text_conditions, value);
  // Help Text
  toggle_statement_field('#proto_statement_help_text_wrapper', window.ps_display_help_text_conditions, value);
  // Required
  toggle_statement_field('#proto_statement_required_wrapper', window.ps_display_required_conditions, value);
  // Destination
  toggle_statement_field('#proto_statement_destination_wrapper', window.ps_display_destination_conditions, value);
  // Message subject
  toggle_statement_field('#proto_statement_msg_subject_wrapper', window.ps_display_msg_subject_conditions, value);
  // Content
  toggle_statement_field('#proto_statement_content_wrapper', window.ps_display_content_conditions, value);
  // Role name
  toggle_statement_field('#proto_statement_role_name_wrapper', window.ps_display_role_name_conditions, value);
  // Attention
  toggle_statement_field('#proto_statement_signal_wrapper', window.ps_display_signal_conditions, value);
  // Subject
  toggle_statement_field('#proto_statement_subject_id_wrapper', window.ps_display_proto_subject_conditions, value);
  // Project
  toggle_statement_field('#proto_statement_service_id_wrapper', window.ps_display_service_id_conditions, value);
  // Suppress if empty
  toggle_statement_field('#proto_statement_suppress_if_empty_wrapper', window.ps_display_suppress_if_empty_conditions, value);
  // Confirmation text
  toggle_statement_field('#proto_statement_confirmation_text_wrapper', window.ps_display_confirmation_text_conditions, value);
  // Max loops
  toggle_statement_field('#proto_statement_loop_max_wrapper', window.ps_display_loop_max_conditions, value);
  // Display qty
  toggle_statement_field('#proto_statement_display_qty_wrapper', window.ps_display_display_qty_conditions, value);
  // Hide on mobile
  toggle_statement_field('#proto_statement_hide_on_mobile_wrapper', window.ps_display_hide_on_mobile_conditions, value);
  // Is Transient
  toggle_statement_field('#proto_statement_is_transient', window.ps_display_is_transient_conditions, value);
  // Format input
  toggle_statement_field('#proto_statement_format_input_wrapper', window.ps_display_format_input_conditions, value);
}

function toggle_statement_field(field_id, list, value) {
  let field= document.querySelector(field_id);

  if (list.includes(value)) {
    field.classList.remove('hide');
  } else {
    field.classList.add('hide');
  }
}