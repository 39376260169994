
var state = (function(){
  window.addEventListener("load", function(event) {
    document.removeEventListener('custom::ajax:success', init)
    document.addEventListener('custom::ajax:success', init)
    init();
  });

  function init(){
    document.querySelectorAll('[data-details-toggler]').forEach(function(element){
      element.addEventListener('click', toggleDetails)
    })
  }

  function toggleDetails(event) {
    event.stopPropagation();
    event.preventDefault();

    const toggler = event.currentTarget
    toggler.classList.toggle('show')

    const target = document.querySelector('[data-details="' + event.currentTarget.dataset.detailsToggler + '"]')
    target.classList.toggle('show')
  }

  return {
    init: init
  }
})()
