(function(){
  document.addEventListener('custom::ajax:success', init)

  window.addEventListener("ajax:success", init);
  window.addEventListener("ajax:error", init);
  window.addEventListener("ajax:complete", init);
  window.addEventListener("invalid", init);

  window.addEventListener("load", function(event) {
    init()
  });


  function init(){
    var elements = document.querySelectorAll('[data-file-input]');

    elements.forEach(element => {
      const fileInput = element.querySelector('input[type="file"]')
      fileInput.removeEventListener('change', fileInputChanged);
      fileInput.addEventListener('change', fileInputChanged);
    })
  }

  function clearFile(event){
    var target = event.currentTarget;

    var parent = target.closest('.file-input-field');
    parent.classList.remove('has-file');
    var fileInput = parent.querySelector('input[type="file"]');
    fileInput.value = ''
    var label = fileInput.parentElement.querySelector('label');
    label.innerText = label.dataset.text;
  }

  function fileInputChanged(event){
    var fileInput = event.currentTarget;
    var label = fileInput.parentElement.querySelector('label');
    label.dataset.text = label.innerText;

    var files = Array.from(fileInput.files).map(x=> x.name).join(',')
    label.innerText = files;
    var parent = fileInput.closest('.file-input-field');
    if (parent) {
    parent.classList.add('has-file')

      var clearFileInput = parent.querySelector('[data-clear-file-input]');
      if (clearFileInput) {
        clearFileInput.removeEventListener('click', clearFile)
        clearFileInput.addEventListener('click', clearFile)
      }
    }
  }
})();