/**
 * Created by chris on 8-7-16.
 */

var historyTimeperiod = '1yr';
var graph_type = 'grafiek';   // grafiek|tabel
var graph_types = ["integer", "float", "list", "vert_radio", "horz_radio"];
var graphdata = {};
var tablePagesize = 5;    // Number of rows on a page
var startDate;
var endDate;
var timeFormat = 'd-M-yy';
var blok = '';
var gridLines = 0;

// Add leading zeros to number (default is 2 positions)
Number.prototype.pad = function(size) {
    var s = String(this);
    while (s.length < (size || 2)) {s = "0" + s;}
    return s;
};

// Create Google Charts chart view
// data: complete data object
function drawChart(data) {
    var dataTable = new google.visualization.DataTable();

    // remove first two fields (date string and waarnemer) and switch date/value
    // (data order must be x-axis, y-axis)
    var observations = data.observations;
    observations.forEach( function(el) {
        el.splice(0, 2);
        el.unshift(el.splice(1,1)[0]);
    });

    // in assets/stylesheets/_charts.scss
    var cssClassNames = {headerRow: 'zvbTableHeader'};

    makeDate(observations, 0);
    makeTooltips(observations);

    convertValue(data, observations, 1);

    dataTable.addColumn('date');       // datum
    dataTable.addColumn('number');     // waarde

    // A column for custom tooltip content
    dataTable.addColumn({type: 'string', role: 'tooltip', 'p': {'html': true}});
//  var start = makeStartdate(historyTimeperiod);
    console.group('drawChart');
    console.log('start: ' + startDate + ', end: ' + endDate);
    console.log('timeFormat: ' + timeFormat + ', gridLines: ' + gridLines);

    var xTicks = datesToTicks(startDate, endDate);
    var minMax = calc_y_min_max(observations);
    var yTicks = makeYAxis(minMax[0], minMax[1]);

    var options = {
        title: data.external_name,
        width: '100%',
        height: '500',
        // hAxis: {format: timeFormat},
        hAxis: {
            format: timeFormat,
            ticks: xTicks,
            minorGridlines: {
                count: gridLines
            },
            viewWindow: {
                min: new Date(xTicks[0]),
                max: new Date(xTicks.slice(-1)[0])
            }
        },
        vAxis: {
            ticks: yTicks,
            viewWindow: {
                min: minMax[0],
                max: minMax[1]
            }
        },
        legend: {position: 'none'},
        tooltip: {isHtml: true},
        allowHtml: true,
        cssClassNames: cssClassNames
    };

    // Changes labels on y-axis if xticks present
    // eg. vAxis: { ticks: [{v:1, f:'ja'}, {v:2, f:'min of meer'}, {v:3, f:'nee'}] },
    if (data.xticks.length > 1) {
        xTicks = [];
        data.xticks.forEach(function(e) {
            xTicks.push({v:e[0], f:e[1]});
        })
        options.vAxis = {ticks: xTicks};
    }

    console.log(observations);
    dataTable.addRows(observations);

    var formatter_datum = new google.visualization.DateFormat({pattern: "d MMM yyyy HH:mm"});
    formatter_datum.format(dataTable, 0);
    var chart = new google.visualization.ScatterChart(document.getElementById('chart'));
    chart.draw(dataTable, options);
    console.groupEnd();

}

// Create Google Charts table view
function drawTable(lines, data) {
    var dataTable = new google.visualization.DataTable();

    // remove first (date string) field
    var observations = data.observations;
    observations.forEach( function(el) {
        el.splice(0, 1);
        el.unshift(el.splice(2,1)[0]);
    });

    makeDate(observations, 0);
    convertValue(data, observations, 2);

    // No conversion from text (ja/nee) to numeric
    dataTable.addColumn('date', 'datum');           // datum
    dataTable.addColumn('string', 'waarnemer');     // waarnemer
    if (data.data_type == 'integer' || data.data_type == 'float') {
        dataTable.addColumn('number', 'waarde');    // waarde
        makeNumeric(observations, 2);
    } else {
        dataTable.addColumn('string', 'waarde');    // waarde
    }

    // in assets/stylesheets/_charts.scss
    var cssClassNames = {headerRow: 'zvbTableHeader'};

    lines = (lines == 0) ? tablePagesize : lines

    var options = {
        // showRowNumber: true,
        width: '100%',
        height: '100%',
        allowHtml: true,
        page: 'enable',
        pageSize: lines,
        sortColumn: 0,
        sortAscending: false,
        cssClassNames: cssClassNames
    };

    dataTable.addRows(observations);

    // document.getElementById('js_chart_buttons').innerHTML = "<div><input type='radio' class='radio_buttons' value='graph' name='view_select' checked='checked'><label>grafiek</label><input type='radio' class='radio_buttons' value='table' name='view_select'><label>tabel</label></div>";

    // https://developers.google.com/chart/interactive/docs/reference#dateformat
    var formatter_datum = new google.visualization.DateFormat({pattern: "d MMM yyyy HH:mm"});
    formatter_datum.format(dataTable, 0);
    var table = new google.visualization.Table(document.getElementById('chart'));
    table.draw(dataTable, options);
}

// Convert (string) date to date object
// Date can be date string or unix timestamp
// arr: data
// veld: date element in arr
function makeDate(arr, veld) {
    for (i=0; i<arr.length; i++) {
        if (typeof arr[i][veld] == 'string') {
            d = dateStrToArr(arr[i][veld])
            arr[i][veld] = new Date(d[0], d[1]-1, d[2], d[3], d[4], d[5]);
        } else {
            arr[i][veld] = new Date(arr[i][veld]);
        }
    }
}

// Convert datetime string into datetime components
// d: datetime as string jjjj-mm-dd uu:mm[:ss]
// returns: array j,m,d,h,m,s (s maybe empty)
function dateStrToArr(d) {
    var pat = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):?(\d{0,2})/;
    var match = pat.exec(d);

    match.splice(0,1)
    return match.slice(0,6)
}

// Convert string value to numeric (faulty values in database)
// arr: data
// veld: field in arr
function makeNumeric(arr, veld) {
    for (i=0; i<arr.length; i++) {
        if (typeof(arr[i][veld]) == "string") {
            arr[i][veld] = parseInt(arr[i][veld]);
        }
    }
}

// Convert text value to numeric using xticks array (only graph, not table)
function makeTick(ticks, inhoud) {
    for (var i = 0; i<ticks.length; i++) {
        var el=ticks[i];
        if(el[1] == inhoud){
            return el[0];
        }
    }
}

// Convert value field to correct value
function convertValue(data, observations, veld) {
    observations.forEach(function(item) {
        if (data.data_type == 'integer') {
            item[veld] = parseInt(item[veld]);
        } else if (data.data_type == 'float') {
            item[veld] = parseFloat(item[veld]);
        } else if (data.xticks.length > 0 && graph_type != 'tabel') {
            item[veld] = makeTick(data.xticks, item[veld]);
        }
    });
}

// Add tooltips to data array
// css: div.google-visualization-tooltip
// arr: data array: [0]: date, [1]: value
function makeTooltips(arr) {
    for (i=0; i<arr.length; i++) {
        arr[i].splice(2, 0, createCustomHTMLContent(new Date(arr[i][0]), arr[i][1]));
    }
}

// Create html tooltip
function createCustomHTMLContent(d, w) {
    d1 = d.getDate() + '-' + (d.getMonth()+1) + '-' + d.getFullYear() + ' ' + d.getHours() + ':' + d.getMinutes().pad();
    return '<p>' + d1 + ' waarde: ' + w + '</p>';
}

// Create x-as labels
function datesToTicks(start, end) {
    console.group('datesToTicks');
    var tijd;
    var dat;
    var day = 60*60*24*1000;
    switch (blok) {
        case 'u':
            tijd = 60*60*1000;
            dat = tomorrow(start);
            break;
        case '3u':
            tijd = 60*60*1000*3;
            dat = tomorrow(start);
            break;
        case 'dg':
            tijd = day;
            dat = tomorrow();
            break;
        case '3dg':
            tijd = day * 3;
            dat = tomorrow();
            break;
        case 'wk':
            tijd = day * 7;
            dat = nextMonday();
            break;
        case '2wk':
            tijd = day * 14;
            dat = nextMonday();
            break;
        case 'mn':
            tijd = 1;
            dat = nextMonth();
            break;
        case '3mn':
            tijd = 3;
            dat = nextQuarter();
            break;
        case '6mn':
            tijd = 6;
            dat = nextQuarter();
            break;
    }

    var ticks = [];
    switch (blok) {
        case 'u':
        case '3u':
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            while (dat >= start) {
                ticks.push(dat);
                dat = new Date(dat.getTime() - tijd);
            }
            break;
        case 'dg':
        case '3dg':
            while (dat >= start) {
                ticks.push(dat);
                dat = new Date(dat.getTime() - tijd);
            }
            break;
        case 'wk':
            while (dat >= lastMonday(start)) {
                ticks.push(dat);
                dat = new Date(dat.getTime() - tijd);
            }
            break;
        case '2wk':
            while (dat >= start) {
                ticks.push(dat);
                dat = new Date(dat.getTime() - tijd);
            }
            break;
        case 'mn':
        case '3mn':
        case '6mn':
            while (dat >= new Date(start.getFullYear(), start.getMonth(), 1)) {
                ticks.push(dat);
                dat = addMonth(dat, -tijd);
            }
            break;
    }
    console.groupEnd();
    return ticks.reverse();
}

// Return start of month before or after date
function addMonth(dat, months) {
    var j = dat.getFullYear();
    var m = dat.getMonth() + months;
    if (m < 0) {
        j -= 1;
        m += 12;
    } else if (m > 11) {
        j += 1;
        m -= 12;
    }
    return new Date(j, m, 1);
}

// Return last Monday before date (start of week)
function lastMonday(dat) {
    dat = dat || new Date();
    var dagnr = dat.getDay()>0 ? dat.getDay() : 7;
    if (dagnr != 1) {
        return new Date(dat.getTime() - (dagnr-1) *60*60*24*1000)
    } else {
        return dat;
    }
}

// Return next Monday after date (start of week)
function nextMonday(dat) {
    dat = dat || new Date();
    var dagnr = dat.getDay()>0 ? dat.getDay() : 7;
    return new Date(dat.getTime() + (8-dagnr) *60*60*24*1000)
}

// Return next day after date 00:00:00
function tomorrow(dat) {
    dat = dat || new Date();
    var d = new Date(dat.getTime() + 60*60*24*1000)
    d.setHours(0);
    d.setMinutes(0);
    d.setSeconds(0);
    return d;
}

// Return first day of this quarter
function thisQuarter(dat) {
    dat = dat || new Date();
    var month = dat.getMonth();
    return new Date(dat.getFullYear(), Math.floor(month/3) * 3, 1);
}

// Return first day of next quarter
function nextQuarter(dat) {
    dat = dat || new Date();
    var month = dat.getMonth();
    return new Date(dat.getFullYear(), (Math.floor(month/3)+1) * 3, 1);
}

// First day of next month (midnight pm)
function nextMonth(dat) {
    dat = dat || new Date();
    var then = dateAddNow(28, dat);
    return new Date(then.getFullYear(),then.getMonth(),1);
}

// Midnight of date, default am, pm with switch
function midnight(d, pm) {
    pm = typeof pm !== 'undefined' ? pm : false;
    if (pm) {
        return new Date(d.getFullYear(),d.getMonth(),d.getDate(), 23, 59, 59);
    } else {
        return new Date(d.getFullYear(),d.getMonth(),d.getDate());
    }
}

// Today plus days (days can be negative)
function dateAddNow(days) {
    var ms = 60*60*24*days*1000;
    return new Date(new Date().getTime()+ms)
}

// Return array with ticks for y-axis
function makeYAxis(min, max) {
    var i = max / 5;
    var v = min;
    var arr = [];
    while (v <= max) {
        arr.push(v);
        v += i;
    }
    return arr;
}

// Set graph layout parameters
function refreshPeriod(period) {
    var year;
    var dat;
    var day = 60*60*24*1000;
    var now = new Date();
    switch (period) {
        case 'today':
            timeFormat = 'H:mm';
            startDate = midnight(new Date());
            endDate = tomorrow(startDate);
            gridLines = 2;
            blok = '3u';
            break;
        case '2wk':
            timeFormat = 'd MMM';
            startDate = midnight(new Date(now.getTime() - 14*day));
            endDate = tomorrow();
            gridLines = 2;
            blok = '3dg';
            break;
        case '1mn':
            timeFormat = 'd MMM';
            // startDate = addMonth(now,-1);
            startDate = lastMonday(new Date(now.getTime() - day * 7 * 4));
            endDate = nextMonday();
            gridLines = 6;
            blok = 'wk';
            break;
        case '3mn':
            timeFormat = 'd MMM';
            startDate = addMonth(now,-3);
            endDate = tomorrow();
            gridLines = 1;
            blok = '2wk';
            break;
        case '1yr':
            timeFormat = 'd-M-yy';
            year = new Date().getFullYear()-1;
            dat = new Date(now.setFullYear(year));
            startDate = thisQuarter(dat);
            endDate = nextQuarter();
            gridLines = 2;
            blok = '3mn';
            break;
        case '3yr':
            timeFormat = 'd-M-yy';
            year = new Date().getFullYear()-3;
            dat = new Date(now.setFullYear(year));
            startDate = thisQuarter(dat);
            endDate = nextQuarter();
            gridLines = 5;
            blok = '6mn';
            break;
    }

}

// Calculate difference in secs. between two dates
function calc_timelapse(d1, d2) {
    var v;
    if (d1 > d2) {
        v = d1 - d2
    } else {
        v = d2 - d1
    }
    return Math.round(v/1000);

}

/*
 * Round to number of decimals
 *	excel_round(3.14532, 0) -> 3
 *	excel_round(3.14532, 2) -> 3.14
 *	excel_round(-3.14532, 2) -> -3.14
 *	excel_round(31415.92654, -2) -> 31500
 *	excel_round(-31415.92654, -2) -> -31500
 */
function excel_round_up(value, decimals) {
    multiplier=Math.pow(10, Math.abs(decimals));
    if (decimals>=0) {
        result=value*multiplier;
        if (result>=0) result=Math.ceil(result); else result=Math.floor(result);
        result=result/multiplier;
    } else {
        result=value/multiplier;
        if (result>=0) result=Math.ceil(result); else result=Math.floor(result);
        result=result*multiplier;
    }
    return result
}

/*
 * Round to number of decimals
 *	excel_round(3.14532, 0) -> 4
 *	excel_round(3.14532, 2) -> 3.15
 *	excel_round(-3.14532, 2) -> -3.15
 *	excel_round(31415.92654, -2) -> 31400
 *	excel_round(-31415.92654, -2) -> -31400
 */
function excel_round_down(value, decimals) {
    multiplier=Math.pow(10, Math.abs(decimals));
    if (decimals>=0) {
        result=value*multiplier;
        if (result>=0) result=Math.floor(result); else result=Math.ceil(result);
        result=result/multiplier;
    } else {
        result=value/multiplier;
        if (result>=0) result=Math.floor(result); else result=Math.ceil(result);
        result=result*multiplier;
    }
    return result
}

/*
 * Geheel eigen functie om te bepalen wat de min en maxwaarden op de Y-as zijn.
 */
function calc_y_min_max(data) {
    min_val=null;
    max_val=null;
    for (var i=0;i<data.length;i++ ) {
        value=parseFloat( data[i][1]);
        min_val=min_val ? Math.min(min_val, value) : value;
        max_val=max_val ? Math.max(max_val, value) : value;
    }
    // Now we have the lowest and highest number from the list.
    // Continue mathematical aproach
    if (min_val==max_val) dif_val1=0; else dif_val1=min_val;
    dif_val2= max_val - dif_val1;
    dif_val3= Math.log( dif_val2) / Math.LN10;
    dif_val4= Math.floor( dif_val3);

    min_adj= excel_round_down(min_val, -dif_val4-1);
    max_adj= excel_round_up(max_val, -dif_val4-1);
    if (min_adj===0) {
        if (max_val<max_adj*0.2) {
            max_adj=max_adj*0.2;
        } else if (max_val<max_adj*0.4) {
            max_adj=max_adj*0.4;
        }
    }
    return [min_adj, max_adj];
}

function closeGraph(tagId) {
    $('#graph').remove();
    $('.cgm-graph-on').removeClass('cgm-graph-on');
    if (tagId) {
        $('#' + tagId).parents('div.row.form-group.header').siblings('div.form-group.row').removeAttr('hidden');
    }
}

function changePeriod(periode) {
    historyTimeperiod = periode;
}

function changeType(type) {
    graph_type = type;
}

// (re)draw chaart
function redrawGraph(lines, data) {
    // TODO soms foutmelding: VM12517:23 Uncaught ReferenceError: google is not defined

    show_graph= $.inArray(data.data_type, graph_types)>=0 && data.is_array_bool==0 && $('#graph');
    if (! show_graph) {
        changeType('tabel');
        $('.btn-group').hide();
    }
    if (graph_type == 'grafiek') {
        drawChart(data);
    } else {
        drawTable(lines, data);
    }
}

// Retrieve data after change and redraw graph
function rereadData(lines, attributeUrl) {
    refreshPeriod(historyTimeperiod);
    time_lapse = calc_timelapse(endDate, startDate)

    if (attributeUrl.indexOf('?')) {
        attributeUrl += "&time=" + time_lapse;
    } else {
        attributeUrl += "?time=" + time_lapse;
    }
    console.log('url: %s', attributeUrl);  // bijv. clients/13/proto_attributes/860/show_history?time=32054400

    //  "_={timestamp}" wordt automatisch toegevoegd door 'cache: false' !!
    $.ajax({url: attributeUrl, dataType: "json", cache: false}).done(function (data) {
        // response voorbeeld:
        // {"id":860,"external_name":"score EDIZ","data_type":"integer","is_array_bool":0,"observations":[["10 sep 2015 00:00","Co Ordinator 1","11",1441836000000],["14 sep 2015 00:00","Co Ordinator 1","12",1442181600000],["18 feb 2016 16:48","Co Ordinator 1",8,1455810522000],["18 feb 2016 16:54","Co Ordinator 1",9,1455810870000],["18 feb 2016 16:55","Co Ordinator 1",0,1455810933000],["18 feb 2016 16:56","Co Ordinator 1",5,1455810985000]],"list":[[1441836000000,"11"],[1442181600000,"12"],[1455810522000,8],[1455810870000,9],[1455810933000,0],[1455810985000,5]],"xticks":[],"xmin_max":{}}

        // dummy data for test
        // var data = {"id":860,"external_name":"score EDIZ","data_type":"integer","is_array_bool":0,"observations":[["10 sep 2015 00:00","Co Ordinator 1","11",1441836000000],["14 sep 2015 00:00","Co Ordinator 1","12",1442181600000],["18 feb 2016 16:48","Co Ordinator 1",8,1455810522000],["18 feb 2016 16:54","Co Ordinator 1",9,1455810870000],["18 feb 2016 16:55","Co Ordinator 1",0,1455810933000],["18 feb 2016 16:56","Co Ordinator 1",5,1455810985000]],"list":[[1441836000000,"11"],[1442181600000,"12"],[1455810522000,8],[1455810870000,9],[1455810933000,0],[1455810985000,5]],"xticks":[],"xmin_max":{}};
        console.log(data);

        graphdata = data;

        redrawGraph(lines, data);

    });
}

