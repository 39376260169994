(function(){
  var searchTimeout = 0;

  window.addEventListener("load", function(event) {
    document.removeEventListener('custom::ajax:success', init)
    document.addEventListener('custom::ajax:success', init)

    init()
  });

  function init(){
    const elements = document.querySelectorAll('[data-bs-toggle-trigger]');
    elements.forEach( function (element) {
      if (element) {
        element.removeEventListener('click', onToggleSearch)
        element.addEventListener('click', onToggleSearch)
      }
    });
  }

  function onToggleSearch(event){
    var element = event.currentTarget;
    var mainElement = document.querySelector(element.dataset.bsToggleTrigger)
    const toggleElements =  mainElement.querySelectorAll('[data-bs-toggle]');
    toggleElements.forEach( function (element) {
      if (element.dataset.toggle != 'buttons') {
        if (element.classList.contains('hide')) {
          element.classList.remove('hide')
        } else {
          element.classList.add('hide')
        }
      }
    });
  }
})();