window.addEventListener('load', function(event) {
	/* On data type 'table' check free list to enter labels */
	$('#proto_attribute_data_type').change(function(event) {
		if ($(this).val()=='table') {
			if ($('#proto_attribute_free_list:checked').length==0) {
				$('#proto_attribute_free_list').click();
			}
		} else {
      const show_when_values=$('#proto_attribute_free_list').parent('div.row').data('showWhenValues')
			if (show_when_values) {
				if (show_when_values.split(',').indexOf($(this).val())<0) {
					$('#proto_attribute_free_list').prop('checked', false);
				}
			}
		}
	});

  /* Check 'hide' if not 'searchable' */
  $("#proto_attribute_searchable").change(function(event) {
    if (!$(this).prop('checked')) {
      $("#proto_attribute_hide").prop('checked', true);
    }
  })

  onloadProtoAttributes();
});

// onLoad Document
function onloadProtoAttributes() {
  onchangeProtoAttributeDataType($('#proto_attribute_data_type').val());

  $('#proto_attribute_data_type').change(function(event) {
    onchangeProtoAttributeDataType(event.target.value);
  });
}

function onchangeProtoAttributeDataType(value) {
  var valid_range_title=null;
  if ('media_file'.match(new RegExp(value))) {valid_range_title='image, audio, video, document'}
  $('#proto_attribute_valid_range').attr('title', valid_range_title);
}

window.onloadProtoAttributes = onloadProtoAttributes
window.onchangeProtoAttributeDataType = onchangeProtoAttributeDataType