/* Autocomplete functions and bindings */
var initAutocomplete=function(doc) {
  /* Any autocomplete field referencing a target-id field */
  $(".autocomplete:input[data-ac-target-id]:not([data-ac-custom='true'])").autocomplete(getAutocompleteForIdOptions);

  /* Any other free input autocomplete field */
  $(".autocomplete:input:not([data-ac-target-id]):not([data-ac-custom='true'])").autocomplete(freeAutocompleteOptions);

  $('.autocomplete').focus(function(event) {
    // Show autocomplete list directly on focus
    $(this).autocomplete('search');
  });


  /* Clear nested attributes fields for destroy */
  $('.destroy_field:checkbox', doc).change( function(event) {
    const elm=event.currentTarget;
    if (elm.checked) {
      $($(elm).data('clear')).val('');
      $($(elm).data('clear')).attr('disabled', true);
    } else {
      $($(elm).data('clear')).attr('disabled', false);
    }
  })

  $('div.button-bar').on('click','input[name="commit"][value!="inloggen"]', function(event) {
    $('.form-group.autocomplete input.form-control.autocomplete').each(function () {
      if ($(this).length) {
        procesAutocompleteError($(this),event);
      }
    });
  });

  // Used in management reports with optional autocomplete fields
  $('div.button-bar').on('click','input[name="execute"]', function(event) {
    var selectedOption = $('.management_report_selection .form-check .selected').text();
    var textField = null;
    switch (selectedOption) {
      case 'coördinator':
        textField = $('#person_acname');
        break;
      case 'huisartspraktijk':
        textField = $('#organization_acname');
        break;
      case 'gemeente':
        textField = $('#municipality_acname');
        break;
    }
    if (textField) {
      procesAutocompleteError(textField,event,(textField.val() != ''));
    }
  });
}

/* Generic Abstract options Object for Autocomplete items requiring setting an :id value in ac-target-id field. Clone - deep copy - this object and set iets source: option. */
var getAutocompleteForIdOptions= {
  minLength: 0,
  source: function( request, response ) {
    $.getJSON( $(this.element).data('ac-url'), {
      autocomplete: 1,
      current_id: $(this.element).data('ac-current-id'),
      term: request.term
    }, response);
  },
  select: function( event, ui ) {
    // A valid item has been selected from the menu
    // console.log("Autocomplete select: " + ui.item);
    var val= ui.item ? ui.item.id : '';
    var target = $(this).data('ac-target-id');
    $(this).siblings(target).attr('value', val);
    $(this).attr('value', ui.item.value);
    $(this).change();
  },
  change: function( event, ui) {
    // Field left (blurred) and value changed
    // console.log("Autocomplete change: " + ui.item);
    if (!ui.item) {
      // No valid item selected
      procesAutocompleteError($(this))

      var target = $(this).data('ac-target-id');
      $(this).siblings(target).attr('value', null);
      $(this).attr('value', '');
    }
  },
  response: function( event, ui) {
    // Autocomplete menu responded to entry change
    // console.log("Autocomplete response: " + event.target.value);
    if (event.target.value==='') {
      // Former value removed, clear target ID
      var target = $(this).data('ac-target-id');
      $(this).siblings(target).attr('value', null);
      $(this).attr('value', '');
    }
  },
  open: function( event, ui) {
    var wrapper = $(this).closest('.form-group');
    removeAutocompleteError(wrapper);
  }
};


/* List names */
var freeAutocompleteOptions=
  {source: function( request, response ) {
    $.getJSON( $(this.element).data('ac-url'), {
      autocomplete: 1,
      term: request.term
    }, response);
  },
  minLength: 0
};


/**
 * Show or remove error message for autocomplete input element
 * @param elem
 * @param event
 * @param no_empty true: suppress submit if no item from list selected
 */
function procesAutocompleteError(elem, event, no_empty) {
  no_empty = no_empty || false;
  var wrapper = elem.closest('.form-group');
  // Not always present: target_id
  var target=elem.siblings('.target_id').first();
  if (target.length>0 && (!target.val() || target.val() == '') && no_empty) {
    if (event) {
      event.preventDefault();
    }
    var msg=wrapper.find('.form-control.autocomplete').data('invalid-input-msg');
    wrapper.addClass('form-group-invalid');
    if (!wrapper.find('span.text-help').length) {
        wrapper.find('.autocomplete').after('<span class="text-help">'+msg+'</span>');
    }
  } else {
      removeAutocompleteError(wrapper);
  }
}

// Remove error message from autocomplete input element
function removeAutocompleteError(wrapper) {
    wrapper.removeClass('form-group-invalid');
    wrapper.find('span.text-help').remove();
}

window.initAutocomplete = initAutocomplete;
window.getAutocompleteForIdOptions = getAutocompleteForIdOptions;
window.freeAutocompleteOptions = freeAutocompleteOptions;
window.procesAutocompleteError = procesAutocompleteError;
window.removeAutocompleteError = removeAutocompleteError;
