window.addEventListener('load', function(event) {
	initRuntimeService(document);
});

var initRuntimeService=function(document) {
	/* Assigned actors to roles can be dragged to available people div */
	$(".role_members .actors.draggable li").draggable({
		scope: 'members',
		revert: 'invalid',
    cursor: "crosshair",
		start: function(events, ui) {$(".role_members .droppable").addClass('dragged')},
		stop: function(events, ui) {$(".role_members .droppable").removeClass('dragged')}
	});

	/* Role where people can be dropped to become an actor, or can be dropped from another role */
	$(".role_members .role.droppable").droppable({
		scope: 'members',
		hoverClass: 'drop-hover',
		tolerance: 'pointer',
		drop: drop_members
	});

	/* Available people - not part of a role yet - can be dragged to a role */
	$(".role_members .people.draggable li").draggable({
		scope: 'members',
		revert: 'invalid',
    cursor: "crosshair",
		start: function(events, ui) {$(".role_members .role.droppable").addClass('dragged')},
		stop: function(events, ui) {$(".role_members .role.droppable").removeClass('dragged')}
	});

	/* Available div where actors can be dropped to be removed from their role */
	$(".role_members .people.droppable").droppable({
		scope: 'members',
		accept: '.actors li',
		hoverClass: 'drop-hover',
		drop: drop_members
	});
};

window.initRuntimeService = initRuntimeService

var drop_members= function(events, ui) {
	const drag_id= $(ui.draggable).attr("id");
	const drag_type= $(ui.draggable).attr("type");
	const drop_id= $(this).attr("id");
	const url= $("#project_members_js.role_members").data("url");
	$.post(url, {_method: 'patch', actor_type: drag_type, actor_id: drag_id, role_id: drop_id});
}