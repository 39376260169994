var mfa = (function(){
  window.addEventListener("load", function(event) {
    document.removeEventListener('custom::ajax:success', init)
    document.addEventListener('custom::ajax:success', init)
    init();
  });

  function init(){
    var tokenInputs = document.querySelectorAll('.tfa_token_nr');
    Array.from(tokenInputs).forEach(function(element){
      element.removeEventListener('keyup', handleKeyUp)
      element.addEventListener('keyup', handleKeyUp)
      element.removeEventListener('keydown', handleKeyDown)
      element.addEventListener('keydown', handleKeyDown)
    })

    if (tokenInputs && tokenInputs.length > 0){
      document.removeEventListener("paste", handlePaste);
      document.addEventListener("paste", handlePaste);

      tokenInputs[0].removeEventListener('paste', handlePaste);
      tokenInputs[0].addEventListener('paste', handlePaste)
    }
  }

  function reInit(){
    var tokenInputs = document.querySelectorAll('.tfa_token_nr');
    Array.from(tokenInputs).forEach(function(element){
      element.removeEventListener('keyup', handleKeyUp)
      element.addEventListener('keyup', handleKeyUp)
      element.removeEventListener('keydown', handleKeyDown)
      element.addEventListener('keydown', handleKeyDown)
    })
  }

  function handleKeyDown(event){
    var key = event.keyCode || event.charCode;
    var target = event.currentTarget;

    target.dataset.oldValue = target.value;

    var currentValue = target.value
    if (currentValue && currentValue.length == 1 && (key != 8 && key != 46)){
      var parent = target.closest('.tfa_tokens');
      var index = Array.from(parent.children).indexOf(target);
      var newIndex = index + 1;
      if (newIndex < parent.querySelectorAll('input').length && newIndex >= 0){
        parent.querySelectorAll('input')[newIndex].focus();
      }
    }
  }

  function handleKeyUp(event){
    var target = event.currentTarget;

    var parent = target.closest('.tfa_tokens');
    var index = Array.from(parent.children).indexOf(target);
    var newIndex = index + 1;

    var key = event.keyCode || event.charCode;

    if (key == 8 || key == 46){
      newIndex = index -1
      if (target.dataset.oldValue){
        target.dataset.oldValue = ''
        return
      }
    }

    if (newIndex < parent.querySelectorAll('input').length && newIndex >= 0 && target.value){
      parent.querySelectorAll('input')[newIndex].focus();
    }

    if (newIndex < index && newIndex >= 0){
      parent.querySelectorAll('input')[newIndex].value = ''
      parent.querySelectorAll('input')[newIndex].focus();
    }
  }

  function handlePaste(event) {
    var paste = (event.clipboardData || window.clipboardData).getData("text");
    var tokenInputs = document.querySelectorAll('.tfa_token_nr');

    var regex = /^\d+-?\d+$/
    if (paste.match(regex)) {
      event.preventDefault();
      event.stopPropagation();

      var input = paste.replace('-', '');
      for (var i = 0; i < input.length; i++) {
        if (i < tokenInputs.length) {
          tokenInputs[i].value = input[i]
        }
      }

      tokenInputs[tokenInputs.length - 1].focus();
    }
  }

  return {
    init: reInit
  }
})();

window.MFA = mfa