(function(){
  window.addEventListener("load", function(event) {
    document.removeEventListener('custom::ajax:success', init)
    document.addEventListener('custom::ajax:success', init)
    init();
  });
  
  function init(){
    var dropUpElements = document.querySelectorAll('.cgm_dropup');
    Array.from(dropUpElements).forEach(function(element){
      element.querySelector('.custom-dropup-toggler').removeEventListener('click', toggleDropUp)
      element.querySelector('.custom-dropup-toggler').addEventListener('click', toggleDropUp)
    })
  }
  
  function toggleDropUp(event){
    var target = event.currentTarget;
    target.closest('.cgm_dropup').classList.toggle('active')
  }
})();