window.addEventListener('load', function(event) {

  /* Autocomplete */
  $('.autocomplete.external_message_line.attribute_name:input').autocomplete(getAutocompleteExternalMessageLineProtoAttributeNamesOptions).
    focus( function() { 
      $(this).autocomplete('search');
    }
  );

	$('#external_message_line_show_level_global').click( function() {
		$('div.subject div.row :input').attr('disabled', true).addClass('disabled');
		$('div.service div.row :input').attr('disabled', true).addClass('disabled');
	});

	$('#external_message_line_show_level_subject').click( function() {
		$('div.subject div.row :input').attr('disabled', false).removeClass('disabled');
		$('div.service div.row :input').attr('disabled', true).addClass('disabled');
	});

	$('#external_message_line_show_level_service').click( function() {
    $('div.subject div.row :input').attr('disabled', true).addClass('disabled');
		$('div.service div.row :input').attr('disabled', false).removeClass('disabled');
	});
  
  $('input[name="external_message_line[show_level]"]:checked').click();
});


/* List attribute names */
var getAutocompleteExternalMessageLineProtoAttributeNamesOptions=
  {source: function( request, response ) {
    var level= $( "input[name='" + $(this.element).data('level-name') + "']:checked").val();

    var proto_subject_code= $( "#" + $(this.element).data('proto-subject-id')).val();
    if (level=='global' || proto_subject_code=='*') proto_subject_code=null;
    var proto_service_code= $( "#" + $(this.element).data('proto-service-id')).val();
    if (level!='service' || proto_service_code=='*') proto_service_code=null;

    $.getJSON( $(this.element).data('ac-url'), {
      autocomplete: 1,
    	level: level,
      subject_group_name: null,
      proto_subject_code: proto_subject_code,
      proto_service_code: proto_service_code,
      term: request.term
    }, response);
  },
  minLength: 0
};