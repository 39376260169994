
var state = (function(){
  window.addEventListener("load", function(event) {
    document.removeEventListener('custom::ajax:success', init)
    document.addEventListener('custom::ajax:success', init)
    init();
  });

  function init(){
    document.querySelectorAll('[data-state-toggler]').forEach(function(element){
      element.addEventListener('click', setState)
    })
  }

  function setState(event) {
    event.stopPropagation();
    event.preventDefault();

    const attendee = event.currentTarget.closest('[data-attendee]')
    const stateElement = attendee.querySelector('[data-state]')
    stateElement.value = event.currentTarget.dataset.stateToggler; 
    colorStates(stateElement.value, stateElement.closest('.appointment-date'));
  }

  function colorStates(value, referenceElement){
    referenceElement.querySelectorAll('[data-state-toggler]').forEach(function(element){
      if (element.dataset.stateToggler == value){
        element.classList.add('active')
      } else {
        element.classList.remove('active')
      }
    })
  }

  return {
    init: init
  }
})()

