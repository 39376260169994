var clientPostFlag = (function(){

  function init(){
    document.removeEventListener('custom::ajax:success', initClientPostFlags)
    document.addEventListener('custom::ajax:success', initClientPostFlags)

    initClientPostFlags();
    initFlagFilters();
  }

  function initClientPostFlags(){
    var targets = document.querySelectorAll('[data-client-post-flag]');
    Array.from(targets).forEach(function(target){
      target.removeEventListener('click', onFlagged)
      target.addEventListener('click', onFlagged)
    });
  }

  function initFlagFilters(){
    var targets = document.querySelectorAll('[data-flag-filter]');
    Array.from(targets).forEach(function(target){
      target.removeEventListener('click', onFlagFilterClicked)
      target.addEventListener('click', onFlagFilterClicked)
    });
  }

  function onFlagged(event){
    event.stopPropagation()
    event.preventDefault()
    var target = event.currentTarget;
    var postUrl = "/clients/" + target.dataset.clientId + "/client_posts/" + target.dataset.clientPostFlag + "/flag"

    target.classList.toggle('flagged')

    //force opacity on mobile device
    if (target.classList.contains('flagged')){
      target.style.opacity = '1.0'
    } else {
      target.style.opacity = '0.1'
    }

    var form_data = new FormData();
    form_data.append('flag', target.dataset.flagged != '1');

    Rails.ajax({
      type: 'patch',
      url: postUrl,
      data: form_data,
      dataType: 'json',
      success: function(data) {
        var customEvent = new CustomEvent('custom::ajax:success', {
          detail: {
          }});
        document.dispatchEvent(customEvent);
      }
    })
  }

  function onFlagFilterClicked(event){
    var target = event.currentTarget;
    var classElement = target.closest('.flag');
    classElement.classList.toggle('flagged')
  }

  return {
    init: init
  }
})();

window.clientPostFlag = clientPostFlag;

document.addEventListener('DOMContentLoaded', function(){
  window.clientPostFlag.init()
})
