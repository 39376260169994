var CGMDevice = (function(){

  function isMobile(){
    var mq = window.matchMedia( "(max-width: 991px)" );
    if (mq.matches) {
      return true;
    }
    return false
  }

  function isIE(){
    return document.body.classList.contains('ie');
  }

  function isIpad(){
    var isIpadUserAgent = /iPad/i.test(navigator.userAgent)
    if (isIpadUserAgent == true) { return true; }

    var isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;

    return isIpad
  }

  function isIphone(){
    if (document.body.dataset['device'] && document.body.dataset['device'] == 'iPhone'){
      return true;
    }

    return false;
  }

  return {
    isMobile: isMobile,
    isIE: isIE,
    isIpad: isIpad,
    isIphone: isIphone
  }
})();

window.CGMDevice = CGMDevice;

export default CGMDevice;