import CGMDevice from '../root/device'

var MessagesElement = (function(){
  window.addEventListener("load", function(event) {
    document.removeEventListener('custom::ajax:success', scrollToBottom)
    document.addEventListener('custom::ajax:success', scrollToBottom)

    scrollToBottom(false);
  });

  function scrollToBottom(onTextAreaFocus) {
    var commentsDiv = document.getElementById("client_comments");
    if (!commentsDiv) {
      return;
    }

    var newMessageElement = document.querySelector('[data-new-message-mobile]')

    if (onTextAreaFocus == true) {
      //Keyboard adds a lot of additional screen size. Scrolling would put input element out of focus
      if (CGMDevice.isIphone() && newMessageElement) {
        return;
      }

      //Keyboard adds a lot of additional screen size. Scrolling would put input element out of focus
      if (CGMDevice.isIpad() && newMessageElement) {
         return;
      }

      //Scroll commentsArea (for big messages on desktop)
      var commentsArea = document.querySelector('.comments_area');
      if (commentsArea) {
        commentsArea.scrollTop = commentsArea.scrollHeight;
      }

      //Scroll window (for other mobile devices) keeps focus on last line in input element
      window.scrollTo(0, document.body.scrollHeight);
    } else {
      //Scroll comments to bottom (body on mobile and commentsDiv on desktop)
      commentsDiv.scrollTop = commentsDiv.scrollHeight;
      window.scrollTo(0, document.body.scrollHeight);
      return;
    }
  }

  return {
    scrollToBottom: scrollToBottom
  }
})();

window.MessagesElement = MessagesElement;