import placeholder from '../components/placeholder'

(function(){
  var searchTroughPost = false;
  var searchTimeout = 0;
  var postTriggeredBySearch = false;

  window.addEventListener("load", function(event) {
    document.removeEventListener('custom::ajax:success', init)
    document.addEventListener('custom::ajax:success', init)

    document.addEventListener("ajax:success", setFocusToSearchInputs);

    document.removeEventListener('custom::ajax:filter_changed', initFilterChanged)
    document.addEventListener('custom::ajax:filter_changed', initFilterChanged)
  });

  function init(){
    postTriggeredBySearch = false;

    initSearch()
    initSubmitCheckboxes()
  }

  function initFilterChanged(event){
    var tableIds = event.detail.tableId.replace('[', '').replace(']', '').split(',')
    console.log('search: filter changed, submitting tables')
    window.setTimeout(function() {
      tableIds.forEach(function (tableId) {
        var targetElement = document.getElementById(tableId.trim());
        if (targetElement) {
          submitForm(targetElement, targetElement.dataset.search);
        }
      });
    }, 10);
  }

  function initSearch() {
    Array.from(document.querySelectorAll('[data-keydown-search]')).forEach(function(searchElement){
      searchElement.removeEventListener('keyup', handleKeyUp)
      searchElement.addEventListener('keyup', handleKeyUp)

      searchElement.removeEventListener('search', handleSearch)
      searchElement.addEventListener('search', handleSearch)
    });

    Array.from(document.querySelectorAll('[data-reset-search]')).forEach(function(resetElement){
      resetElement.removeEventListener('click', handleReset)
      resetElement.addEventListener('click', handleReset)
    });
  }

  function initSubmitCheckboxes(){
    Array.from(document.querySelectorAll('[data-submit-checkbox]')).forEach(function(searchElement){
      searchElement.removeEventListener('click', handleChecked)
      searchElement.addEventListener('click', handleChecked)
    });
  }

  function handleKeyUp(event){
    console.log('search: handing keyUp')
    postTriggeredBySearch = true;
    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(function(){
      var element = event.target;
      var form = element.form;
      if (element.dataset.filterAdditionalTargets) {
        var targets = element.dataset.filterAdditionalTargets.split(',')
        targets.forEach(function(target){
          console.log('search: handling other target')
          var targetElement = document.getElementById(target);
          submitForm(targetElement, element.value || '');
        })
      }
      submitForm(form);
    }, 1000);
  }

  function handleSearch(event){
    handleKeyUp(event)
  }

  function handleReset(event){
    var element = event.target;
    var form = element.form;
    if (!form){
      form = element.closest('form');
    }
    var search = form.querySelector('[type="search"]')
    if (search){
      search.value = '';
    }

    submitForm(form, '')

    var searchElement = element.closest('.full-width-search');
    if (searchElement) {
      var inputElement = searchElement.querySelector('input')
      if (inputElement && inputElement.dataset.filterAdditionalTargets) {
        var targets = inputElement.dataset.filterAdditionalTargets.split(',')
        targets.forEach(function (target) {
          var targetElement = document.getElementById(target);
          submitForm(targetElement, inputElement.value);
        })
      }
    }
  }

  function handleChecked(event){
    var target = event.currentTarget;

    Rails.fire(target.form, "submit")
  }

  function submitForm(form, queryString){
    console.log('submitting form')
    searchTroughPost = true;

    if (queryString){
      form.dataset.search = queryString;
    } else {
      form.dataset.search = '';
    }

    if (form.dataset.remote && form.tagName == 'FORM') {
      //just use rails
      Rails.fire(form, "submit");
    } else if (form.tagName == 'FORM') {
      form.submit();
    } else if (form.dataset.zvLoadUrl) {
      var elm = form;
      placeholder.create(elm)

      var correctedUrl = elm.dataset.zvLoadUrl;
      if (queryString){
        correctedUrl.endsWith('?') ? correctedUrl += '&external_search=' + queryString : correctedUrl += '?external_search=' + queryString
      }

      Rails.ajax({
        type: 'get',
        url: correctedUrl,
        dataType: 'script',
        success: function(data) {
          elm.innerHTML= data.html;
          window.tb_initDocument(elm);
          placeholder.remove(elm)
          var customEvent = new CustomEvent('custom::ajax:success', {
            detail: {
            }});
          document.dispatchEvent(customEvent);
        },
        complete: function(){
          placeholder.remove(elm)
        }
      })
    }
  }

  function setFocusToSearchInputs(){
    if (searchTroughPost) {
      searchTroughPost = false;

      setTimeout(function () {
        Array.from(document.querySelectorAll('input[type="search"]')).forEach(function (searchElement) {
          searchElement.selectionStart = searchElement.selectionEnd = 10000
          searchElement.focus();
          postTriggeredBySearch = false
        })
      }, 0);
    }
  }
})();