(function(){
  try {
    var isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;

    if (isIpad) {
      console.log('device is iPad')
      if (!getIPadCookie('x-device-type')) {
        setIpadCookie('x-device-type', 'tablet')
         window.location.reload();
      }
    }
  } catch(ex){
    
  }
  
  function setIpadCookie(name,value) {
    var date = new Date();
    date.setTime(date.getTime() + (1000*24*60*60*1000));
    var expires = "; expires=" + date.toUTCString();
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }
  
  function getIPadCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }
})();