var inline_conditions=new Object();
var initial_triggers=new Object();

window.addEventListener('load', function(event) {
	/**
	* Trigger alle attributen die voor een inline conditie van belang zijn zodra het scherm geladen is
	*/
	for (var attrId in initial_triggers) {
		$(initial_triggers[attrId]).change();
	}

	/* Conditional check_box for form input fields */
  $(":checkbox[name='conditional_cb']").change(function(event) {
		let wrapper;
  	if ($(this).data('wrapper-id')) {
			wrapper= $("#" + $(this).data('wrapper-id'));
  	} else {
  		wrapper= $(this).closest('div');
  	}
    /* Hide input field attribute */
    $(wrapper).toggleClass('hide_input');
    /* Clear input field attribute */
    if (!this.checked) $(wrapper).find("input, textarea, select").not(this).val(null);
  });

  /* Radio buttons: set selected class on label of checked button */
  $('label > :radio:checked').each(function(event) {
  	$(this).closest('label').addClass('selected');
  });
  $('label > :radio').change(function(event) {
  	$(this).closest('div').find('label').removeClass('selected');
  	$(this).closest('label').addClass('selected');
  });

	/**
	* Zet voor alle input velden die een 'required' attribuut hebben, dit attribuut uit indien zij verborgen zijn. Doe dit op moment van browservalidatie
	* TIJDELIJK uitgezet omdat dit ervoor zorgt dat je een paar keer op de Submit button moet klikken voor er wat gebeurt. Onduidelijk hoe die invalid-event precies werkt.
	* Zet de browser validaties derhalve tijdelijk uit.
  * Update aug 2019: dit zal nog steeds niet werken. De invalid event wordt immers vóór het submit event aangeroepen.
  * Opgelost door bij de toggle van de nodisplay class het required attribuut aan/uit te zetten.
	*/
	// $("form#runtime_observation_form :input[required]").on('invalid', function(event) {
	// 	var elm=$(event.target);
	// 	if (elm.is(':hidden')) {
	// 		elm.attr('required', null);
	// 		return false;
	// 	}
	// });

	/**
	* Zet voor elk attribuut met een input-veld dat verplicht is, zijn hidden field genaamd 'required' op false indien het gehele attribuut verborgen is (als gevolg van conditionele vraag)
	* Zet de waarde van alle niet-van-toepassing zijn attributen (ELSE-tak) op leeg
	* Geef met Allow_blank aan dat de waarden leeg in de historie mogen worden opgeslagen.
	*/
	$("form#runtime_observation_form").submit( function(event) {
		var form=$(event.currentTarget);
		$(".nodisplay :hidden[name*='[required]']", form).val(false);
		$(".nodisplay :input[name*='[value]']", form).val('');
		$(".nodisplay :hidden[name*='[allow_blank]']", form).val(true);
		return true;
	});

	/* If submit button with Formnovalidate has been called, unrequire all fields */
	$(':submit[formnovalidate]').click(function(event) {
		$('input[id$="_required"]:hidden').val(false);
	});
	/* If submit button with .skip class has been called, set skip parameter to skip over saving of attributes */
	$(':submit.skip').click(function(event) {
		$('input#skip:hidden').val(1);
	});

  /* GetAttr table: if any columns should be required unless whole row is blank then (un)require them.
    This simulates field browser validation for table attributes */
  $('[data-required-ids]').change(function(event) {
    var required_ids= $(this).data('required-ids');
    if ($(this).val()) {
      // We have a (selected) value
      update_required_attributes(required_ids, true);
    } else {
      // We blanked out the input field or unselected
      // Find any input field in the same row that is not blank
      var blank_row= true;
      $(this).closest(".row[data-row-nr]").first().find("[data-required-ids]").each(function(index, el) {
        if ($(el).val()) {
          blank_row= false;
          return false;
        }
      });
      // If we have a blank row, unrequire all columns
      if (blank_row) update_required_attributes(required_ids, false);
    }
  });


  /* Statement expression contains copy_to="attribute_name". Copy the entered value to the target attribute field.
     As this could be a composed field - e.g. date time - copy it to the same index field as the source element */
  $('[data-copy-to-attr] > input, [data-copy-to-attr] > select').change(function(event) {
    // console.log(event.target.value);
    // Find the current index number this input field is as a child from his parent (to support composed fields)
    let input_children= Array.from(event.target.parentElement.querySelectorAll('input, select'));
    let elm_idx= input_children.indexOf(event.target);

		const target_attr_id= event.target.parentElement.dataset.copyToAttr
    if (target_attr_id) {
      let target_wrapper= document.getElementById("attribute_" + target_attr_id);
      // Now find the analog input fied in the target wrapper, elm_idx will mostly be 0.
			const target_child= target_wrapper.querySelectorAll('input, select').item(elm_idx)
      if (target_child) {
        target_child.value= event.target.value;
      }
    }
  })

	/* Delete runtime observations */
	$("#delete_form_trigger").click(function(event) {
    $('#delete_confirmation_dlg').modal('show');
	});

	$("div.row.slider, div.row.combi_slider").each(function(event) {
		var input_field= $('input', this);
		var slider= $('.component', this).slider({
			animate: 'fast',
			range: 'min',
			value: input_field.val(),
			step: parseFloat( input_field.data('step') || 1),
			min: parseFloat( input_field.data('min') || 0),
			max: parseFloat( input_field.data('max') || 1000),
			slide: function( event, ui ) {
				$(this).siblings('input').val(ui.value);
			},
		});
		input_field.change(function(event) {
			slider.slider('option', 'value', event.target.value);
		});
	});

	$("a#observationReportsBtn").click( function(event) {toggle_observation_reports(this);});
	$("a#observationMembersBtn").click( function(event) {toggle_observation_members(this);});

	/* Vertical table lay-out: act like an accordeon on group headers */
	$('.collapseable.vertical_layout[data-group-key]').click(function(event) {
		const group= $(event.currentTarget).data('group-key')
		if (group) {
			$(".row[data-group='" + group + "']").toggleClass('hide');
		}
	});

  $('.show_new_table_rows_container a.show_new_table_rows_btn').click(function(event) {
    var show_rows= $(this).data('show-rows');
    var max_new_rows= $(this).data('max-new-rows');
    var remain_rows= max_new_rows-show_rows;

    $(this).closest('.stmt').children('.hide[data-row-nr]:lt(' + show_rows + ')').removeClass('hide');
    if (remain_rows > 0) {
      // Reduce the remaining number of extra rows we can add
      $(this).data('max-new-rows', remain_rows);
    } else {
      // Remove the whole container with the underlying button pressed
      $(this).closest('div.show_new_table_rows_container').remove();
    }
  });

});

/**
* Tabelregels kunnen meerdere classes formaat "condnnnnn" of "cond-nnnnn" hebben. Abstraheer elke class van dit formaat en haal daaruit
* het regel-ID van de conditie statement. In de array inline_conditions is per regel-ID opgeslagen of de conditie TRUE of FALSE is.
* Een negatieve regel-ID betekent dat deze tabelregel in de ELSE-tak van de conditie zit.
*/
function eval_inline_conditions(condition_id) {
	$(".stmt.cond"+condition_id + ", .stmt.cond-"+condition_id + ", .stmt .report .cond"+condition_id).each(function(index, element) {
		let showItem=true;
		element.classList.forEach(function (className) {
			if (showItem==true && className.search(/^cond/)==0 ) {
				let condId= parseInt( className.substr(4), 10);
				if ((inline_conditions[Math.abs(condId)]==true && condId>0) || (inline_conditions[Math.abs(condId)]==false && condId<0)) {
					showItem=true;
				} else {
					showItem=false;
				}
			}
		});
		// v4.x we now have hidden fields some place else refered to by data-stmt-id attribute
		var stmt_id= document.querySelector("#hidden_" + element.dataset.stmtId );

		if (showItem) {
			element.classList.remove('nodisplay');
			if (stmt_id) stmt_id.classList.remove('nodisplay');
      if ($(element).hasClass('required')) $(':input.required', element).attr('required', '');

		} else {
			element.classList.add('nodisplay');
			if (stmt_id) stmt_id.classList.add('nodisplay');
      if ($(element).hasClass('required')) $(':input[required]', element).removeAttr('required');
		}
	});
}

function toggle_observation_reports(elm) {
	if ($("article.index.reports").is(':hidden')) {
		$(elm).addClass('active');
		$("article.index.reports").show();
	} else {
		$(elm).removeClass('active');
		$("article.index.reports").hide();
	}
}

function toggle_observation_members(elm) {
	if ($("article.index.members").is(':hidden')) {
		$(elm).addClass('active');
		$("article.index.members").show();
	} else {
		$(elm).removeClass('active');
		$("article.index.members").hide();
	}
}

// Update the required attribute for all input fields identified by one of the ids.
function update_required_attributes(input_ids, required) {
  for (var i=0; input_ids.length, i < input_ids.length; i++) {
    $('#'+ input_ids[i]).prop('required', required);
  }
}

window.inline_conditions = inline_conditions;
window.initial_triggers = initial_triggers;
window.eval_inline_conditions = eval_inline_conditions;
window.toggle_observation_reports = toggle_observation_reports;
window.toggle_observation_members = toggle_observation_members;
window.update_required_attributes = update_required_attributes;