var tb_initDocument=function(doc) {
  $('.sClose', doc).click(function(event) {
    /* Act on closeIcon */
    var close_id= $(this).data('sclose-id');
    $("#" + close_id).remove();
  });

  /* Bootstrap Tooltips */
  if (window.useTooltips) {
    // header exceptions, anything with a title attribute not being empty
    $('header *[title][title!=""][data-placement]', doc).not('.notooltip').tooltip();
    $('header *[title][title!=""]', doc).not('[data-placement]').not('.notooltip').tooltip({placement: 'bottom'});

    // Rest of document
    $('*[title][title!=""]', doc).not('.notooltip').tooltip();
    $('a[title][title!=""][data-remote=true]', doc).not('.notooltip').on('shown.bs.tooltip', function (event) {
      // For tooltips on remote links, hide them after click on smartphones
      $(this).on('tap', function(event) { $(this).tooltip('hide')});
    });

    /* Set tooltip function on elements where the tooltip content to display is NOT
    * the default title attribute, but refered by the data-tip-id attribute.
    * Note that the title attribute is NOT allowed. */
    $('.tip[data-tip-id]', doc).each(function(_index) {
      $(this).tooltip({ title: getTooltipContent($(this))});
    });

    $('a.tip[data-tip-id][data-remote=true]', doc).not('.notooltip').on('shown.bs.tooltip', function (event) {
      // For tooltips on remote links, hide them after click on smartphones
      $(this).on('tap', function(event) { $(this).tooltip('hide')});
    });
  }

  /* Enable Bootstrap JS popovers for any element having a <SPAN class='button'> tag.
  * Get the content to display with the getHint() function from the sibling HTML tag
  * with a 'hint_content' class
  * Note on sanitize: false. Enable links (<a href>) in popovers which is weird because the default sanitation should allow that (docs). Enable in Bootstrap 5?
  */
  $('.hint > span.button', doc).each(function(_index) {
    $(this).popover({content: getHint($(this)), html: true, sanitize: false, trigger: 'focus hover', container: this.closest('.label_wrapper') || this.closest('.hint') || 'body', placement: getPopoverPlacement});
  });

  /* Attach Click event on created popovers to have them disappear when clicked on. Necessary for smartphones like IOS */
  $('.hint > span.button', doc).on('shown.bs.popover', function (event) {
    var popoverelm= event.currentTarget;
    var popoverid= '#' + $(popoverelm).attr('aria-describedby');
    $(popoverid).on('tap', function(event) {
      $(popoverelm).popover('hide');
    });
  });

  /* End Bootstrap Tooltips */

  /* Bootstrap Modal popup */
  /* Add a data attribute close_modal: true to any link or button within a modal to close and clear it */
  $('div.modal [data-close-modal=true]').click( function(event) {
    $(event.currentTarget).closest('div.modal').modal('hide');
    $('.modal-backdrop').remove();
  });
  /* End Bootstrap Modal popup */

  /* jQuery Datepicker */

  // Birthday fields
  $('input.date_picker[data-date-type="birth_date"]', doc).datepicker(
    {showWeek:false,
     dateFormat: 'dd-mm-yy',
     showOn: "focus",
     changeYear: true,
     changeMonth: true,
     yearRange: "1901:c",
     defaultDate: "-70y"
    }
  );

  // All other date fields
  $('input.date_picker', doc).not('[data-min-date]').datepicker(
    {showWeek:true,
     dateFormat: 'dd-mm-yy',
     showOn: "focus",
     changeYear: true,
     changeMonth: true}
  );

  // Override date fields with a min- and max date
  $('input.date_picker[data-min-date]', doc).each(function(){
    var $this = $(this);
    $this.datepicker(
      {showWeek:true,
        dateFormat: 'dd-mm-yy',
        showOn: "focus",
        changeYear: true,
        changeMonth: true,
        yearRange: "c-30:c+30",
        minDate: $this.data('min-date'),     // Note: this (version) only works with relative dates. Like "+1m +7d"
        maxDate: $this.data('max-date')
      });
  });


  /* End jQuery Datepicker */

  /*  Focus on the first editable field.
      Note: this must be set after binding of any Autocomplete field to prevent the data being lost
  */
  $("form input[type!='hidden'][type!='submit'][type!='reset']:enabled, form select:enabled, form textarea", doc).not('[readonly], .nofocus, .error, .date_picker').first().focus();

  /* View filters
   * Submit form once one of the filter radio buttons has been pressed
   */
  $('.btn-group[data-bs-toggle="buttons"] label').change(function(event) {
    // Use this construction to support remote forms via UJS.
    const form= $(event.currentTarget).closest('form')[0];
    return window.submit_remote_form(form, event);
  });
  $('.btn-group-vertical[data-bs-toggle="buttons"][data-submit="true"] label').change(function(event) {
    // Use this construction to support remote forms via UJS
    const form= $(event.currentTarget).closest('form')[0];
    return window.submit_remote_form(form, event);
  });

  $( "#jobs_container").each(function() {
    /* Click on Jobs Trace button every 3 seconds to show progress bar */
    // TODO dit werkt bij rails-ujs alleen met [0] erachter, anders doet de click() het niet, dus geen page refresh, dus geen update van de progressbar. Waarom ????
    setInterval( function() {
      const btn= document.getElementById('jobs_trace_btn')
      if (btn) btn.click();
      }, 3000);
  });

  /* Sortable list containers: */
  $('.list-container.sort, .list-group.sort').sortable({
    axis: 'y',
    dropOnEmpty: false,
    cursor: 'crosshair',
    opacity: 0.4,
    delay: 200,
    scroll: true,
    stop: function(event, ui) {
      $(ui.item.children('.draggable')).tooltip('dispose');
    },
    update: function(event, ui) {
      // [Content Security Policy] Use Rails.ajax to pass CSP Nonce (and others) so a .js.erb template will be executed by that nonce.
      Rails.ajax({
        type: 'post',
        data: $(this).sortable('serialize'),
        dataType: 'script',
        url: $(this).data('sort-url')})
    }
  });

  /* Clear search criteria button */
  $('div.clearSearchBtn').click(function(event) {
    // Click on "a#clearSearchBtn" link won't work for some reason, so:
    window.location= document.querySelector('a#clearSearchBtn').href;
  });

    // link open in new tab #2476 kijksluiter
  $('a.newTab').each(function(i, obj){
      obj.setAttribute('target', '_blank');
      obj.setAttribute('rel', 'noopener noreferrer');
    });

}

window.tb_initDocument = tb_initDocument;

/* Get the content for popovers from a (hidden) element with class 'hint_content'. */
function getHint(element) {
  return $(element).siblings('.hint_content').html();
}

/* Get the tooltip content from a (hidden?) HTML element with #ID defined by the data-tip-id
* attribute of the tooltip element; in stead of the default title attribute. */
function getTooltipContent(element) {
  const tip_id= $(element).data('tip-id')
  if (tip_id) {
    return $('#'+tip_id).html();
  }
}

/* Determine how to set the popover / tooltip placement. Unless set by data attribute 'placement' override by screen width setting */
function getPopoverPlacement(popoverElm, triggerElm) {
  var placement= $(triggerElm).data('placement');
  if (!placement) {
    if ($( document ).width() >= 768 ) {
      if (triggerElm.closest('.label_wrapper')){
        console.log('label_wrapper big size: setting popopver placement right')
        placement= 'right';
      } else {
        console.log('default big size: setting popopver placement left')
        placement = 'bottom';
      }
      /* Part of checkbox list, probably no labels. So hint is aligned left, place tooltip right. */
      if ($(triggerElm).closest('.check_boxes').length>0) {
        console.log('checkboxes: setting popopver placement right')
        placement= 'right'
      }
    } else {
      console.log('default small size: setting popopver placement bottom')
      placement= 'bottom'
    };
  }
  console.log("final placement", placement)
  return placement;
}

/* Get redis progress*/
function get_redis_progress(url, target) {
  $.ajax({
    dataType: "json",
    url: url,
    async: true,
    cache: false,
    success: function(data) {
      var perc= parseInt(data.job_progress);
      if (isNaN(perc)) {
        location.reload();
      } else {
        target.value= perc;
        target.title= perc + "%";
        target.style= "width:" + perc + "%";
      }
    }
  });

}