import Rails from "@rails/ujs"
window.Rails = Rails;
Rails.start();

// "jquery": "^3.7.1",
// import './root/load_jquery'
import "jquery" //from importmaps
// window.jQuery = $ (jquery has no default exports is not a real module)
// window.$ = jquery

// "jquery-ui": "1.13.2",
// import "./root/load_jquery-ui"
// import "./root/load_jquery-ui_imports"
import "jquery-ui"; //from importmaps

// "bootstrap": "^5.3.2",
import 'bootstrap' //from importmaps

import "./controllers"
import './root'
import './polyfills/index'
import './admin/index'
import './includes/index'
import './plugins/index'
import './components/index'
import './berichten/index'
import './appointments/index'
import './app/bridge'
import './app/test_ipad'