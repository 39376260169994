var bridge = (function(){
  function init(){
    document.removeEventListener('custom::ajax:success', init)
    document.addEventListener('custom::ajax:success', init)

    if (window.HtmlViewer || (window.webkit && window.webkit.messageHandlers.HtmlViewer)) {
      Array.from(document.querySelectorAll('[data-app]')).forEach(function(element){
        element.removeEventListener('click', onDataAppClicked)
        element.addEventListener('click', onDataAppClicked)
      })
    }

    if (window.HtmlViewer || (window.webkit && window.webkit.messageHandlers.HtmlViewer)) {
      Array.from(document.querySelectorAll('[data-confirm]')).forEach(function(element){
        element.removeEventListener('click', onDataAppClicked)
        element.addEventListener('click', onDataAppClicked)
      })
    }

    var formActionOverrideElements = document.querySelectorAll("[form_action]")
    formActionOverrideElements.forEach(function(formActionOverrideElement){
      formActionOverrideElement.removeEventListener('click', onFormActionOverrideElementClicked)
      formActionOverrideElement.addEventListener('click', onFormActionOverrideElementClicked)
    })
  }

  function onDataAppClicked(event) {
    var target = event.currentTarget;
    var method = target.dataset.callback;

    if (window.HtmlViewer) {
      window.HtmlViewer[method]();
    } else if (window.webkit && window.webkit.messageHandlers) {
      window.webkit.messageHandlers.HtmlViewer.postMessage(method);
    }
  }

  function showNotification(text, message, link){
    var currentMessageElement = document.querySelector('[data-app-message]')
    if (currentMessageElement){
      currentMessageElement.remove();
    }

    var messageElement = link ? document.createElement('a') : document.createElement('div');
    messageElement.dataset.appMessage = '1'
    messageElement.className = 'inline-notification'
    messageElement.href = link;

    var iconContainer = document.createElement('div')
    var icon = document.createElement('span')
    iconContainer.appendChild(icon);

    var messageBody = document.createElement('div');
    var messageBodyTitle = document.createElement('div');
    messageBodyTitle.innerText = text;
    var messageBodyText = document.createElement('div');
    messageBodyText.innerText = message;
    messageBody.appendChild(messageBodyTitle);
    messageBody.appendChild(messageBody);

    messageElement.appendChild(iconContainer);
    messageElement.appendChild(messageBody);

    var inlineNotificationElement = document.querySelector('[data-inline-notification]');
    if (inlineNotificationElement){
      messageElement.className = 'inline-notification'
      inlineNotificationElement.appendChild(messageElement);
    } else {
      messageElement.className = 'body-notification';
      document.body.appendChild(messageElement);
    }
  }

  function refresh_messages(){
    try {
      window.refresh_client_post()
    } catch(ex){
      //
    }
  }

  function onKeyboardShowing(){

  }

  function logout(){
    if (window.HtmlViewer) {
      window.HtmlViewer['logOut']();
    } else if (window.webkit && window.webkit.messageHandlers) {
      window.webkit.messageHandlers.HtmlViewer.postMessage('logOut');
    }
  }

  function reloadWebview(){
    if (window.HtmlViewer) {
      window.HtmlViewer['reloadWebview']();
    } else if (window.webkit && window.webkit.messageHandlers) {
      window.webkit.messageHandlers.HtmlViewer.postMessage('reloadWebview');
    }
  }

  function activatePinCode(){
    if (window.HtmlViewer) {
      window.HtmlViewer['activatePincode']();
    } else if (window.webkit && window.webkit.messageHandlers) {
      window.webkit.messageHandlers.HtmlViewer.postMessage('activatePincode');
    }
  }

  function onFormActionOverrideElementClicked(event){
    var target = event.currentTarget;
    var form = target.closest('form')

    form.dataset.originalMethod = form.method
    form.dataset.originalAction = form.action
    form.method = target.getAttribute('form_method')
    form.action = target.getAttribute('form_action')

    form.submit()

    window.setTimeout(function() {
      form.method = form.dataset.originalMethod
      form.action = form.dataset.originalAction
      console.log(form.dataset.originalAction)
      console.log(form.action)
    }, 10);

    event.preventDefault()
    return false
  }

  return {
    init: init,
    showNotification: showNotification,
    refresh_messages: refresh_messages,
    onKeyboardShowing: onKeyboardShowing,
    logout: logout,
    reloadWebview: reloadWebview,
    activatePinCode: activatePinCode
  }
})();

window.addEventListener('load', function(event) {
  bridge.init();
});

window.bridge = bridge
