var CookieStorage = (function(){
    function getCookie(name) {
      const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
      if (match) {
        return match[2];
      }
      else{
        return null;
      }
    }

    function setCookie(name, value) {
      var expiration_date = new Date();
      var cookie_string = '';
      expiration_date.setFullYear(expiration_date.getFullYear() + 1);

      document.cookie = name + "=" + value + "; path=/; expires=" + expiration_date.toUTCString();
    }

    return {
      setCookie: setCookie,
      getCookie: getCookie
    }
  })();

  window.CookieStorage = CookieStorage;

  export default CookieStorage