var placeholder = (function(){

  function create(element) {
    element.dataset.placholderTimeout = setTimeout(function () {
      element.innerHTML = "<div class='placeholders show' data-placeholder='1'><div class='placeholder'><span class='flex-grow'>...</span><span class='icon'>?</span></div><div class='placeholder'><span class='flex-grow'>...</span><span class='icon'>?</span></div><div class='placeholder'><span class='flex-grow'>...</span><span class='icon'>?</span></div><div class='placeholder'><span class='flex-grow'>...</span><span class='icon'>?</span></div></div>";
    }, 600) + '';
  }
  function remove(element) {
    var placeholder = element.querySelector('[data-placeholder]')
    if (placeholder) {
      placeholder.remove();
    }
    if (element.dataset.placholderTimeout) {
      clearTimeout(parseInt(element.dataset.placholderTimeout));
    }
  }

  return {
    create: create,
    remove: remove
  }
})();

window.placeholder = placeholder;

export default placeholder;