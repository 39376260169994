window.addEventListener("ajax:success", function(event) {
  /* form submitted */
  if (event.target.matches('form.twostep_verifiable, a.twostep_verifiable')) {
    // Form matches given selector, then check twofactor authentication
    if (!window.twofactor_authentication_required(event.detail)) {
      if (event.target.id=="login_as") {
        // POST /<personal>/:id/exec_login_as
        // Login As form, redirect to given location
        location.href= event.detail[0].location;

      } else if (event.target.id=='access_form') {
        // PATCH /people/:id/update#access
        const form_id= document.querySelector('.person #access .card-body')
        if (form_id) {
          window.person_updated(event.detail, form_id);
        }

      } else if (event.target.id=='saml_form') {
        // PATCH /organization_accounts/:id/update_saml
        const contentElm= document.querySelector(event.target.dataset.frame);
        if (contentElm) {
          window.org_account_updated(event.detail, contentElm);
        }
      }
    } else {
      // Mark this Element as waiting for TFA to complete
      event.target.dataset.tfaPending=true;
    }

    if (event.target.dataset.tfaPending===undefined) {
       window.handleRemoteUpdateForQuerySelector(event, '[data-zv-frame]');
    }
  }

  var customEvent = new CustomEvent('custom::ajax:success', {
    detail: {
    }});
  document.dispatchEvent(customEvent);
});