window.addEventListener('load', function(event) {

  /* Autocomplete */
  $('.autocomplete.report_line.attribute_name:input').autocomplete(getAutocompleteProtoAttributeNamesOptions).
    focus( function() {
      $(this).autocomplete('search');
		});

	$('#report_line_show_level_global').click( function() {
		$('div.subject div.row :input').attr('disabled', true);
		$('div.subject div.row :input').addClass('disabled');

		$('div.service div.row :input').attr('disabled', true);
		$('div.service div.row :input').addClass('disabled');
	});

	$('#report_line_show_level_subject').click( function() {
		$('div.subject div.row :input').attr('disabled', false);
		$('div.subject div.row :input').removeClass('disabled');
		
		$('div.service div.row :input').attr('disabled', true);
		$('div.service div.row :input').addClass('disabled');
	});

	$('#report_line_show_level_service').click( function() {
		$('div.subject div.row :input').attr('disabled', false);
		$('div.subject div.row :input').removeClass('disabled');

		$('div.service div.row :input').attr('disabled', false);
		$('div.service div.row :input').removeClass('disabled');
	});


});

/* List attribute names */
var getAutocompleteProtoAttributeNamesOptions=
  {source: function( request, response ) {
    var level= $('input[name="report_line[show_level]"]:checked').val();
    var subject_group_name= $( "#" + $(this.element).data('subject-group-id')).val();
    if (level=='global' || subject_group_name=='*') subject_group_name=null;
    var proto_subject_code= $( "#" + $(this.element).data('proto-subject-id')).val();
    if (level=='global' || proto_subject_code=='*') proto_subject_code=null;
    var proto_service_code= $( "#" + $(this.element).data('proto-service-id')).val();
    if (level!='service' || proto_service_code=='*') proto_service_code=null;

    $.getJSON( $(this.element).data('ac-url'), {
      autocomplete: 1,
    	level: level,
      subject_group_name: subject_group_name,
      proto_subject_code: proto_subject_code,
      proto_service_code: proto_service_code,
      term: request.term
    }, response);
  },
  minLength: 0
};