(function(){
  window.addEventListener("load", function(event) {
    document.removeEventListener('custom::ajax:success', init)
    document.addEventListener('custom::ajax:success', init)
    init();
  });

  function init(){
    var element = document.querySelector('[data-grow-wrap]')
    if (!element) { return; }

    element.removeEventListener('paste', checkTextAreaHeight)
    element.addEventListener('paste', checkTextAreaHeight)

    element.removeEventListener('keyup', checkTextAreaHeight)
    element.addEventListener('keyup', checkTextAreaHeight)
  }

  function checkTextAreaHeight(event){
    var element = event.currentTarget;
    var length = event.target.value.length;
    if (!length) { length = 1; }

    var selectionStart = event.currentTarget.selectionStart;
    if (!selectionStart) { selectionStart = 1; }

    if (length == selectionStart){
      window.setTimeout(function() {
        element.style.height = "1px";
        element.style.height = (10 + element.scrollHeight) + "px";
        window.MessagesElement.scrollToBottom(true);
      }, 10);
    }

  }
})();