var CGMMobileDialog = (function(){
  window.addEventListener("load", function(event) {
    document.removeEventListener('custom::ajax:success', init)
    document.addEventListener('custom::ajax:success', init)
    init();
  });

  function init(){
    const elements = document.querySelectorAll('[data-dialog-mobile-trigger]')
    elements.forEach( function (element) {
      element.addEventListener('click', toggleMobileDialog)
    })
  }

  function toggleMobileDialog(event){
    const element = event.currentTarget;
    const targetSelector = element.dataset.dialogMobileTrigger
    const target = document.querySelector(targetSelector);

    if (target) {
      target.classList.toggle('active')
      element.classList.toggle('active')

      if (target.classList.contains('active')){
        document.body.classList.add('modal-open')
        const backdrop = document.createElement('div')
        backdrop.className = 'modal-backdrop fade show mobile-modal-backdrop'
        document.body.appendChild(backdrop)
      } else {
        const backdrop = document.querySelector('.mobile-modal-backdrop')
        if (backdrop){
          backdrop.remove();
          document.body.classList.remove('modal-open')
        }
      }
    }
  }

  return {
  }
})();

window.CGMMobileDialog = CGMMobileDialog;