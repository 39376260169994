var refreshScreen;

// Returns all elements with no content
// https://stackoverflow.com/questions/7572123/how-to-detect-an-empty-div-using-jquery
$.expr[':'].nocontent = function(obj, index, meta, stack){
  return !($.trim($(obj).text()).length) && !($(obj).children().length)
};

// Change mouse cursor to pointer when hovering over a completed task to show log
function changeCursor() {
  $('div[id^=task]:contains("completed")').hover(function() {
    $(this).css('cursor','pointer')
  });
}

// Show simple spinner
function showSpinner() {
  var inProgress=$('div[id^=status]:contains("progress")');
  inProgress.parent().prev().find('div[id^=spinner]').text('');
  inProgress.next().text()=='x' ? inProgress.next().text('+') : inProgress.next().text('x');
}

// Update status column in installation tasks screen with latest status text
function refresh() {
  if ($('#installation_id').length) {
    $.ajax({
      url: "/admin/installations/get_status",
      type: "POST",
      data: {installation_id: $('#installation_id').val()},
      format: 'json'
    }).done(function(data) {
      var status = data.tasks_status;
      if (status.length > 0) {
        for (var i = 0; i < status.length; i++) {
          if (status[i]) {
            $('#status_' + (i + 1)).text(status[i]);
          }
        }
        changeCursor();
      }
    }).always(function() {
      // TODO vervangen door 'echte' spinner?
      showSpinner();

      // Cancel refresh and reload page when task is finished so new tsk can be started in InstallationController#index
      if ($('div[id^=status]:contains("progress")').length==0) {
        clearInterval(refreshScreen);
        location.reload();
      }
    });
  } else {
    clearInterval(refreshScreen);
  }

};

window.addEventListener('load', function(event) {
  if (($('div[id^=status]:contains("progress")').length > 0 ||
    $('div[id^=status]:contains("scheduled")').length > 0 ||
    ($('div[id^=status]:contains("completed")').length > 0 && $('div[id^=status]:contains("completed")').length < 15 && $('div[id^=status]:contains("completed")').length != 5)) &&
    $('div[id^=status]:contains("with errors")').length == 0) {
    refreshScreen = setInterval(refresh, 5000);
  }

  changeCursor();
});