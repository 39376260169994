window.addEventListener('click', function(event) {
  if (event.target.dataset && event.target.dataset.action=='click->multi_fields#show_next') {
    multi_fields_show_next(event);
    event.stopPropagation();
    event.preventDefault();
  }
});

window.addEventListener('change', function(event) {
  if (event.target.dataset && event.target.dataset.action=='change->multi_fields#show_next') {
    multi_fields_show_next(event);
    event.stopPropagation();
    event.preventDefault();
  }
});

window.addEventListener('blur', function(event) {
  if (event.target.dataset && event.target.dataset.action=='blur->multi_fields#show_next') {
    multi_fields_show_next(event);
    event.stopPropagation();
    event.preventDefault();
  }
});

function multi_fields_show_next(event) {
  let inputElm= event.target;

  if (event.target.dataset.multiFieldsTarget) {
    // Button pressed
    inputElm= document.getElementById(event.target.dataset.multiFieldsTarget);
  }
  // console.log("Leaving this field with value " + inputElm.value);

  // Determine what the next Element to show will be
  let nextElm= null;
  if (inputElm.dataset.multiFieldsNext=='next') {
    // Set the next SiblingElement
    nextElm= inputElm.nextElementSibling;
  } else if (inputElm.dataset.multiFieldsNext.startsWith('#')) {
    // Find the specified Element by ID
    nextElm= document.querySelector(inputElm.dataset.multiFieldsNext);
  } else  {
    // Find the specified Element by container class
    const contElm= inputElm.closest(inputElm.dataset.multiFieldsNext);
    // And use the next sibling container
    if (contElm) {
      nextElm= contElm.nextElementSibling;
      if (nextElm && nextElm.type=='hidden') nextElm= nextElm.nextElementSibling;  // Skip any hidden field
    }
  }

  if (nextElm) {
    console.log(`Multi_fields next elm:`);
    console.log(nextElm);
    if ( inputElm.value==undefined || inputElm.value.length==0) {
      // Geen waarde ingegeven, verberg alle volgende invoervelden
      if (!nextElm.value || nextElm.value.length==0) nextElm.classList.add('hide');
    } else {
      // Waarde ingevuld, toon volgende veld
      if (nextElm.classList.contains('hide')) {
        nextElm.classList.remove('hide');

        // Bepaal nu welk veld de focus krijgt.
        if (nextElm.autofocus) {
          // Het volgende item zelf omdat daar Autofocus is opgegeven
          nextElm.focus();
        } else {
          let childElm= nextElm.querySelector('[autofocus]');
          if (childElm) {
            // Eén van de childElements met Autofocus van het volgende item
            childElm.focus();
          } else {
            // Dan toch maar het volgende item zelf
            nextElm.focus();
          }
        }

      }
    }
  } else {
    console.log(`Multi_fields next elm: ${nextElm}`)
  }
}