window.addEventListener("ajax:success", function(event) {
  handleRemoteUpdateForQuerySelector(event, '[data-remote="true"][data-zv-frame]');
});

window.addEventListener("click", function(event) {
  if (event.target.dataset.action=='clear_input_fields') {
    clear_input_fields('#' + event.target.dataset.actionId);
    event.preventDefault();
    event.stopPropagation();
  }

})

function clear_input_fields(action_id) {
  const elm= document.querySelector(action_id);

  if (elm) {
    const input_fields= elm.querySelectorAll('input:read-write');
    input_fields.forEach( function (elm) {
      elm.value=null;
    })
  }
}

window.clear_input_fields = clear_input_fields


/* Generic handler for updating HTML content based on one or more selectors
* @example
* window.addEventListener("ajax:success", function(event) {
*    handleRemoteUpdateForQuerySelector(event, '[data-remote="true"][data-zv-frame]');
*  });
*
* Be sure to have a frame and optionally a action data-attribute on those selector elements to define what HTML-part needs to be updated and how (update/replace)
*/
function handleRemoteUpdateForQuerySelector(event, selector) {
  // var [data, status, xhr] = event.detail;
  var data = event.detail[0];
  var status = event.detail[1];
  var xhr = event.detail[2];
  var container;

  if (data.redirect){
    window.location = data.redirect;
    return;
  }

  // tfaPending means there is a MFA dialog waiting. So we should NOT be having a MFA dialog when we do this
  if (event.target.matches(selector) && event.target.dataset.tfaPending===undefined) {
    $(event.target).tooltip('hide');

    if (data.errors===undefined) {
      container= document.querySelector(event.target.dataset.frame)
    } else {
      container= document.querySelector(event.target.dataset.errorFrame)
    }

    if (container) {
      let js_container;
      switch(event.target.dataset.action) {
        case 'replace':
          js_container= container.parent;
          // create HTML from String via Template
          var template = document.createElement('template');
          data.html = data.html.trim();           // Never return a text node of whitespace as the result
          template.innerHTML = data.html;
          container.replaceWith(template.content);
          var customEvent = new CustomEvent('custom::ajax:success', {
            detail: {
            }});
          document.dispatchEvent(customEvent);
          break;
        default:
          js_container= container;
          // Defaults to replacing just the innerHTML
          container.innerHTML= data.html;
          var customEvent2 = new CustomEvent('custom::ajax:success', {
            detail: {
            }});
          document.dispatchEvent(customEvent2);
      }
      // Setup any Tooltips/Popovers, Autocomplete, Conditionals, etc
      window.activateJS(js_container);
    }
    event.stopPropagation();
    event.preventDefault();
  }
}

window.handleRemoteUpdateForQuerySelector = handleRemoteUpdateForQuerySelector