Date.prototype.weekNumber = function () {
  var date = new Date(this.getTime());
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
  // January 4 is always in week 1.
  var week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
}

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

Date.prototype.monday = function () {
  const d = new Date(this.valueOf());
  var day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}

Date.prototype.dateDiffInHoursAndMinutesAsDecimal = function (dateUntil) {
    var dateFrom = new Date(this.valueOf());
    var diffInMinuten = Math.floor((dateUntil - dateFrom) / 1000 / 60);
    var diffInUren = Math.floor(diffInMinuten / 60);
    var absMinuten = Math.abs(Math.floor((diffInMinuten % 60) / 60 * 100));
    absMinuten = absMinuten % 10 == 0 ? absMinuten / 10 : absMinuten.toString().padStart(2, '0');
    if (absMinuten == 0)
        return diffInUren.toString();
    else
        return diffInUren + ',' + absMinuten;
}