window.addEventListener('load', function(event) {
	$( ".progress-bar[data-url]").each(function(index, target) {
		const url= $(target).data('url');
		setInterval( function() {window.get_redis_progress(url, target)}, 1000);
	});

  $("button[name='view_filter'].submit_js").click( function(event) {
    const $filter_area=$('table.index.import');
    $("button[name='view_filter']").removeClass("active");
    $(event.currentTarget).addClass('active');

    switch(event.currentTarget.value) {
      case 'new':
        $('tr.new', $filter_area).show();
        $('tr.misc, tr.outdated', $filter_area).hide();
        break;
      case 'outdated':
        $('tr.new, tr.misc', $filter_area).hide();
        $('tr.outdated', $filter_area).show();
        break;
      case 'all':
        $('tr', $filter_area).show();
        break;
    }
    return false;
  });

  // Initially trigger:
  $("button[name='view_filter'][value='outdated'].submit_js").click();
});