/* Everything on the Dashboard for professionals */
window.addEventListener("click", function(event) {
  switch(event.target.id) {
    case 'network_view_clear_search_btn':
      {
        const form= event.target.closest('form');
        if (form) {
          const search_field= form.querySelector('input[type="search"]');
          if (search_field.value) {
            // Blank out search value and submit
            search_field.value= null;
            window.submit_remote_form(form, event);
            event.stopPropagation();
            event.preventDefault();
          }
        }
      }
      break;
  }

  if (event.target.matches('#message_tabs button') ) {
    // Save the selected messages Tab in a Cookie to reactivate after selecting a filter or sort
    document.cookie= `dashboard_messages_tab= ${event.target.id}; SameSite=Lax; Secure`
  }
});