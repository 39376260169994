/* Everything on the Dashboard for professionals */
window.addEventListener("ajax:success", function(event) {

  const data = event.detail[0];

  switch(event.target.id) {
    case 'organization_account_generate_api_key_btn':
      /* Place the result of Generate API key button in the API-key field of the organization. */
      if (document.querySelector('#organization_account_api_key')) {
        document.querySelector('#organization_account_api_key').value= data.api_key;
      }

      event.stopPropagation();
      event.preventDefault();
      break;
  }

  var customEvent = new CustomEvent('custom::ajax:success', {
    detail: {}});
  document.dispatchEvent(customEvent);
});

/* Edit SAML */
var org_account_edit = function(detail, form_window) {
  const data = detail[0];

  // Editable window:
  form_window.innerHTML= data.html;
  window.tb_initDocument(form_window);
  form_window.querySelector('[aria-invalid="true"]').focus();        // Focus on first field with errors
}

/* Updated SAML */
var org_account_updated = function(detail, form_window) {
  var data = detail[0];

  if (data.saved) {
    location= data.location;
  } else {
    // Toon form opnieuw
    org_account_edit(event.detail, form_window);
  }
}

window.org_account_updated = org_account_updated;