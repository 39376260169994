var PostcodeApi= (function() {
  var postalCodeElement = null;
  var addressNoElement = null;
  var countryCodeElement = null;
  var streetNameElement = null;

  var currentPostalCode = '';
  var currentAdressNo = '';
  var currentCountryCode = '';
  var currentStreetName = '';

  // prevent double check
  let checkingPostalCode = false;

  function init() {

    if (!setInitialValues()){
      return
    }

    $(document).on('blur', '#pc_country_cd', function (evnt) {
      if (countryCodeElement.value != 'NL'){
        //disable_street_field(false);
        disable_address_fields(false)
        return;
      }
      else {
        //disable_street_field(true);
        disable_address_fields(true)
        return;
      }
    });

    $(document).on('blur', '#pc_postal_code,#pc_address_no,#pc_street' , function (evt) {
      checkPostalCode(function(){})
    });


    $("#edit_person").bind('submit', function (e) {
      var moveForward = checkPostalCode(function(){
        $("#edit_person").unbind('submit').submit()
      })

      if (!moveForward){
        e.preventDefault();
        return false;
      }

      return true
    });

  }

  return {
    init: init
  }

  function setInitialValues(){
    postalCodeElement = document.getElementById('pc_postal_code');
    addressNoElement = document.getElementById('pc_address_no');
    countryCodeElement = document.getElementById('pc_country_cd');
    streetNameElement = document.getElementById('pc_street');

    if (!postalCodeElement || !addressNoElement){
      return false
    }

    currentPostalCode = postalCodeElement.value;
    currentAdressNo = addressNoElement.value;
    currentStreetName = streetNameElement.value;
    currentCountryCode = countryCodeElement.value;

    if (!currentCountryCode){
      currentCountryCode = 'NL';
    }

    return true
  }

  function checkPostalCode(callback){
    if (currentPostalCode == postalCodeElement.value && currentAdressNo == addressNoElement.value && currentStreetName == streetNameElement.value) {
      // continue as normal
      return true
    }

    if (!postalCodeElement.value || !addressNoElement.value || (countryCodeElement.value != 'NL' && !streetNameElement.value)){
      // no need to move forward
      return true;
    }

    console.log("checking postal code")

    if (!currentCountryCode){
      currentCountryCode = 'NL';
    }

    if (checkingPostalCode){
      return false
    }

    checkingPostalCode = true

    $.ajax({
      url: "/postal_codes/pc_address",
      type: "GET",
      data: {pc: postalCodeElement.value, no: addressNoElement.value, land: countryCodeElement.value, straat: streetNameElement.value},
      dataType: 'json'
    }).done(function (data) {
      checkingPostalCode = false

      currentPostalCode = postalCodeElement.value;
      currentAdressNo = addressNoElement.value;
      currentCountryCode = countryCodeElement.value;
      currentStreetName = streetNameElement.value;

      var street = data.streetName;
      var city = data.cityName;
      var municipality = data.localityName;
      var latitude = data.latitude;
      var longitude = data.longitude;
      var verified = data.verified;
      window.set_value('#pc_verified', verified);

      if (street && city) {
        window.set_value('#pc_street_hidden,#pc_street', street);
        window.set_value('#pc_city_hidden,#pc_city', city);
        window.set_value('#pc_municipality', municipality);
        window.set_value('#pc_latitude_hidden', latitude);
        window.set_value('#pc_longitude_hidden', longitude);
        window.set_value('#pc_latitude', latitude);
        window.set_value('#pc_longitude', longitude);
        disable_address_fields(true);
      } else {
        disable_address_fields(false);
      }

      callback()
    }).fail(function(jqXHR, textStatus, errorThrown) {
      checkingPostalCode = false

      currentPostalCode = postalCodeElement.value;
      currentAdressNo = addressNoElement.value;
      currentCountryCode = countryCodeElement.value;
      currentStreetName = streetNameElement.value;
      callback()
    });

    // prevent default
    return false
  }

  /**
   * Set status (enabled/disabled) of address fields and empty fields if necessary
   * @param status true: disabled
   */
  function disable_address_fields(status) {
    if (!status && window.dutch_postal_code(document.getElementById('pc_postal_code'))) {
    //  set_value('#pc_street_hidden,#pc_city_hidden,#pc_street,#pc_city,#pc_municipality,#pc_latitude_hidden,#pc_longitude_hidden', '')
    }
    // Do not perform on hidden address elements
    var pcStreetElement = document.getElementById('pc_street');
    if (pcStreetElement){
      pcStreetElement.disabled = status;
    }

    var pcCityElement = document.getElementById('pc_city');
    if (pcCityElement){
      pcCityElement.disabled = status;
    }
  }

  function disable_street_field(status) {
    // in case of non-nl postal codes street needs to be filled in
    var pcStreetElement = document.getElementById('pc_street');
    if (pcStreetElement){
      pcStreetElement.disabled = status;
    }
  }
})();

/* Init on load */
window.addEventListener('load', function (event) {
  PostcodeApi.init();
})