var tableSettings = (function(){
    
  function init(){
    document.removeEventListener('custom::ajax:success', initFilters)
    document.addEventListener('custom::ajax:success', initFilters)

    initFilters();
  }
  
  function initFilters(){
    Array.from(document.querySelectorAll('[data-sort-item]')).forEach(function(sortItem){
      sortItem.removeEventListener('click', onSortChanged)
      sortItem.addEventListener('click', onSortChanged)
    })

    Array.from(document.querySelectorAll('[data-filter-item]')).forEach(function(sortItem){
      sortItem.removeEventListener('click', onFilterChanged)
      sortItem.addEventListener('click', onFilterChanged)
    })
  }
  
  
  function onFilterChanged(event){
    var postParamsData = getTableData(event);
    
    var target = event.currentTarget;
    
    var data = {
      pageId: postParamsData.pageId,
      tableId: postParamsData.tableId,
      filter: target.dataset.filter
    }

    var form_data = new FormData();
    for (var property in data) {
      form_data.append(property, data[property]);
    }

    // placeholder.create(document.getElementById(data.tableId))

    Rails.ajax({
      type: 'post',
      url: postParamsData.postUrl,
      data: form_data,
      dataType: 'json',
      success: function(data) {
        var customEvent = new CustomEvent('custom::ajax:success', {
          detail: {
          }});
        document.dispatchEvent(customEvent);
        
        maybeNotifyOtherFormsOfFilterChange(target, postParamsData);
      }
    })
  }
  
  function maybeNotifyOtherFormsOfFilterChange(target, postParamsData){
    if (postParamsData.tableId.includes(',')) {
      window.setTimeout(function(){
        var filterEvent = new CustomEvent('custom::ajax:filter_changed', {
          detail: {
            pageId: postParamsData.pageId,
            tableId: postParamsData.tableId.replace(target.dataset.currentTableId, 'xx')
          }
        });
        document.dispatchEvent(filterEvent);
      }, 100);
    }
  }
  
  function onSortChanged(event){
    var postParamsData = getTableData(event);
    var target = event.currentTarget;
    
    var data = {
      pageId: postParamsData.pageId,
      tableId: postParamsData.tableId,
      sortDirection: target.dataset.sortItem
    }
    
    var form_data = new FormData();
    for (var property in data) {
      form_data.append(property, data[property]);
    }
    
    // placeholder.create(document.getElementById(data.tableId))
    
    Rails.ajax({
      type: 'post',
      url: postParamsData.postUrl,
      data: form_data, 
      dataType: 'json',
      success: function(data) {
        var customEvent = new CustomEvent('custom::ajax:success', {
          detail: {
          }});
        document.dispatchEvent(customEvent);
      }
    })
  }
  
  function getTableData(event) {
    var target = event.currentTarget;
    var parent = target.closest('[data-table-filter]')
    var pageId = target.dataset.pageId;
    var tableId = target.dataset.tableId;
    var personId = target.dataset.personId;

    if (!pageId) { pageId = parent.dataset.pageId }
    if (!tableId) { tableId = parent.dataset.tableId }
    if (!personId) { personId = parent.dataset.personId }

    var postUrl = "/people/" + personId + "/store_table_settings"

    return {
      pageId: pageId,
      tableId: tableId,
      postUrl: postUrl
    }
  }
  
  return {
    init: init
  }
})();

window.tableSettings = tableSettings;

document.addEventListener('DOMContentLoaded', function(){
  window.tableSettings.init();
})