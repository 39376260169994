var personCheckBox = (function(){
  function init(){

    document.removeEventListener('custom::ajax:success', init)
    document.addEventListener('custom::ajax:success', init)

    $('[data-person-check-box]').find('input[type=checkbox]').on('change', function(event){
      if (this.checked){
        $(this).closest('[data-person-check-box]').addClass('checked')
      } else {
        $(this).closest('[data-person-check-box]').removeClass('checked')
      }
    })

    $('[data-person-check-box]').find('input[type=checkbox]').each(function(){
      if (this.checked){
        $(this).closest('[data-person-check-box]').addClass('checked')
      } else {
        $(this).closest('[data-person-check-box]').removeClass('checked')
      }
    })
  }
  
  return {
    init: init
  }
})();

var customCheckbox = (function(){
  function init(){

    document.removeEventListener('custom::ajax:success', init)
    document.addEventListener('custom::ajax:success', init)

    $('.checkbox-list').find('input[type=checkbox]').on('change', function(event){
      if (this.checked){
        $(this).closest('label').addClass('checked')
      } else {
        $(this).closest('label').removeClass('checked')
      }
    })

    $('.checkbox-list').find('input[type=checkbox]').each(function(){
      if (this.checked){
        $(this).closest('label').addClass('checked')
      } else {
        $(this).closest('label').removeClass('checked')
      }
    })
  }

  return {
    init: init
  }
})();

window.addEventListener('load', function(event) {
  personCheckBox.init();
  customCheckbox.init();
});