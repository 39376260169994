window.addEventListener('load', function(event) {
  
  $('select[name="management_report_column[object_type]"]').click(function() {
      if ($.inArray($('#management_report_column_object_type').val(),['activity','members'])>=0) {
          $('div.management_report_column_completed').show();
      } else {
          $('div.management_report_column_completed').hide();
      }
  })

  $('select[name="management_report_column[object_type]"]').click();

});