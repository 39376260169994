import CGMDevice from '../root/device'

var FileUploadMultiple = (function(){

  function allowMultiple(inputElement) {
    if ($(inputElement).attr('multiple') == 'multiple') {
      return true;
    }
    return false;
  }

  function getFiles(element){
    return element.files;
  }

  function storeFiles(element, files){
    var fileBuffer = new DataTransfer();

    for (let i = 0; i < files.length; i++) {
      fileBuffer.items.add(files[i]);
    }

    element.files = fileBuffer.files;
  }

  function init() {
    document.removeEventListener('custom::ajax:success', init)
    document.addEventListener('custom::ajax:success', init)

    var inputElement = document.getElementById('client_comment_appendices')
    if (inputElement == null){
      inputElement = document.getElementById('client_post_client_comments_attributes_0_appendices')
    }

    if (!inputElement) { return; }

    $(inputElement).prop("value", "");

    inputElement.removeEventListener('change', handleFileInputChanged);
    inputElement.addEventListener('change', handleFileInputChanged);

    if (CGMDevice.isIE()) {
      showFiles(inputElement)
    }
  }

  function handleFileInputChanged(event) {
    var inputElement = event.currentTarget;
    showFiles(inputElement)
  }

  function showFiles(element) {
    var files = getFiles(element);
    if (files == null || files == undefined) { return; }

    var filesElement = element.closest('[data-files]')
    if (files.length > 0){
      filesElement.classList.add('active')
    } else {
      filesElement.classList.remove('active')
    }

    var placeholderElement = filesElement.querySelector('[data-placeholder]');
    placeholderElement.innerHTML = ''
    var contentElement = document.createElement('div')
    contentElement.className = 'file-content';

    var arrowElement = document.createElement('div')
    arrowElement.className = 'arrow-down'

    contentElement.appendChild(arrowElement)
    for (let i = 0; i < files.length; i++) {
      var div = createFilePresentation(files[i].name, i)

      contentElement.appendChild(div);
    }
    if (files.length > 0) {
      placeholderElement.appendChild(contentElement)
    }

    var countElement = element.closest('[data-files]').querySelector('[data-count]');
    countElement.innerHTML = files.length;
  }

  function createFilePresentation(text, index) {
    var fileDisplay = document.createElement('div')
    fileDisplay.classList.add('file-content-item')

    var label = document.createElement('div')
    label.classList.add('text')
    label.innerText = text

    fileDisplay.appendChild(label)

    if (CGMDevice.isIE()) {
      var removeIcon = document.createElement('a')
      removeIcon.className = 'close material-symbols-outlined'
      removeIcon.innerHTML = 'close'
      removeIcon.dataset.index = index
      removeIcon.dataset.removeFile = true
      removeIcon.removeEventListener('click', handleFileInputDelete);
      removeIcon.addEventListener('click', handleFileInputDelete);
      fileDisplay.appendChild(removeIcon)
    }

    return fileDisplay;
  }

  function handleFileInputDelete(event) {
    var inputElement = event.currentTarget.closest('[data-files]').querySelector('input[type="file"]')

    event.stopPropagation();
    event.preventDefault();
    var files = getFiles(inputElement)

    var filesArray = Array.from(files);
    filesArray.splice(event.currentTarget.dataset.index, 1);

    storeFiles(inputElement, filesArray)
    showFiles(inputElement, getFiles(inputElement))
  }

  function convertFileToBase64(file, input) {
    setBase64(file, input);
  }

  function setBase64(file, input) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      $(input).val(reader.result).trigger('change');
    };

    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  window.addEventListener("load", function(event) {
    init();
  });

  return {
    init: init
  }
})();
