window.addEventListener('load', function(event) {bind_conditionals(document)});

var bind_conditionals = function(html) {
  // General form solution for 'radio fields to hide/show on selected values of other fields'
  $(":radio.show_when", html).change(function(event) { onChangeRadioShowWhen(event.target) });
  $(":radio:checked.show_when", html).each( function(index, item) { onChangeRadioShowWhen(item) });

  // General form solution for 'select fields to hide/show on selected values of other fields'
  $("select.show_when", html).change(function(event) { onChangeSelectShowWhen(event.target) });
  $("select.show_when", html).each( function(index, item) { onChangeSelectShowWhen(item) });

  // General form solution for 'select fields to enable/disable on selected values of other fields'
  $("select.disable_when", html).change(function(event) { onChangeSelectDisableWhen(event.target) });
  $("select.disable_when", html).each( function(index, item) { onChangeSelectDisableWhen(item) });

  // General form solution for 'radio fields to enable/disable other selected fields'
  $(":radio.disable_when", html).change(function(event) { onChangeRadioDisableWhen(event.target) });
  $(":radio:checked.disable_when", html).each( function(index, item) { onChangeRadioDisableWhen(item) });

  // General form solution for 'checkbox fields to hide/show on selected values of other fields'
  $(":checkbox.show_when", html).change(function(event) { onChangeCheckboxShowWhen(event.target) });
  $(":checkbox.show_when", html).each( function(index, item) { onChangeCheckboxShowWhen(item) });

  // General form solution for 'checkbox fields to enable/disable on selected values of other fields'
  $(":checkbox.disable_when", html).change(function(event) { onChangeCheckboxDisableWhen(event.target) });
  $(":checkbox.disable_when", html).each( function(index, item) { onChangeCheckboxDisableWhen(item) });
}

/* Generieke actie voor show/hide van elementen obv een radio waarde */
function onChangeRadioShowWhen(source) {
  const items= document.querySelectorAll("[data-show-when-name='" +  source.name + "']");
  items.forEach( function(elm) {
    let when_values= elm.dataset.showWhenValues;
    if (when_values!==undefined) {
      when_values= String(when_values);
      if (when_values.length>0 && when_values.split(',').indexOf(source.value)>-1 ) {
        elm.classList.remove('hide');
      } else {
        elm.classList.add('hide');
      }
    }
  })
}

/* Generieke actie voor een enable/disable van elementen obv een select box */
function onChangeRadioDisableWhen(source) {
  const items= document.querySelectorAll("[data-disable-when-name='" +  source.name + "']");
  items.forEach( function(elm) {
    let when_values= elm.dataset.disableWhenValues
    if (when_values) {
      when_values= String(when_values);
      if (when_values.length>0 && when_values.split(',').indexOf(source.value)>-1 ) {
        elm.classList.remove('hide');
      } else {
        elm.classList.add('hide');
      }
    }
  })
}

/* Generieke actie voor een show/hide van elementen obv een select box */
function onChangeSelectShowWhen(source) {
  const items= document.querySelectorAll("[data-show-when-id='" +  source.id + "']");
  items.forEach( function(elm) {
    let when_values= elm.dataset.showWhenValues
    if (when_values!==undefined) {
      when_values= String(when_values);
      if (when_values.length>0 && when_values.split(',').indexOf(source.value)>-1 ) {
        elm.classList.remove('hide');
      } else {
        elm.classList.add('hide');
      }
    }
  })
}

/* Generieke actie voor een enable/disable van elementen obv een select box */
function onChangeSelectDisableWhen(source) {
  const items= document.querySelectorAll("[data-disable-when-id='" +  source.id + "']");

  items.forEach( function(elm) {
    let when_values= elm.dataset.disableWhenValues;
    if (when_values) {
      when_values= String(when_values);
      if (when_values.length>0 && when_values.split(',').indexOf(source.value)>-1 ) {
        elm.setAttribute("disabled", "");
      } else {
        elm.removeAttribute("disabled");
      }
    }
  })
}

/* Generieke actie voor een show/hide van elementen obv een boolean input veld */
function onChangeCheckboxShowWhen(source) {
  const items= document.querySelectorAll("[data-show-when-id='" +  source.id + "']");
  items.forEach( function(elm) {
    let when_value= elm.dataset.showWhenValues;
    if (when_value!==undefined) {
      if (source.checked==when_value) {
        elm.classList.remove('hide');
      } else {
        elm.classList.add('hide');
      }
    }
  })
}

/* Generieke actie voor een enable/disable van elementen obv een boolean input veld */
function onChangeCheckboxDisableWhen(source) {
  const items= document.querySelectorAll("[data-disable-when-id='" +  source.id + "']");
  items.forEach( function(elm) {
    let when_value= elm.dataset.disableWhenValues;
    if (source.checked==when_value) {
      elm.disabled=true;
    } else {
      elm.disabled=false;
    }
  })
}

window.bind_conditionals = bind_conditionals;