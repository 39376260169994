var CGMModal = (function(){
  function init(){
    document.removeEventListener('custom::ajax:success', init)
    document.addEventListener('custom::ajax:success', init)

    $('[data-modal-show-initial]').modal('show')
  }

  return {
    init: init
  }
})();

window.addEventListener('load', function(event) {
  CGMModal.init();
});