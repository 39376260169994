var calendarHelper = (function(){

  var days = 0;
  var calendar;

  function initListeners(_calendar){
    calendar = _calendar;
    document.removeEventListener('custom::ajax:success', init)
    document.addEventListener('custom::ajax:success', init)

    document.removeEventListener('custom::navigation:change', showAttendees)
    document.addEventListener('custom::navigation:change', showAttendees)

    document.removeEventListener('custom::navigation:change', toggleTimeInputs)
    document.addEventListener('custom::navigation:change', toggleTimeInputs)
    init();
  }

  function init(){
    calendar.toggleAddHighlightsTodays();
    initAddTimes();
    initAddGuests();
    linkRemoveDates();
    showAttendees();
    showDays();
    linkTimes();
  }

  function toggleTimeInputs(event){
    if (event.detail.step == 'description' && event.detail.oldStep == 'dates'){
      const errorElements = document.querySelectorAll('[data-hours-minutes-error]')
      const activeElements = document.querySelectorAll('.date-time:not(.hidden)');

      errorElements.forEach(x => x.classList.add('hidden'))

      activeElements.forEach(x => {
        const hoursEndElement = x.querySelector('[data-hours-end');
        const startElement = x.querySelector('[data-hours-start');
        if (hoursEndElement.value && startElement.value){
          document.querySelectorAll('[data-hours-start]').forEach(function(element){
            element.type = 'text'
          })

          document.querySelectorAll('[data-hours-end]').forEach(function(element){
            element.type = 'text'
          })
          return true;
        } else {
          document.querySelectorAll('[data-hours-start]').forEach(function(element){
            element.type = 'time'
          })

          document.querySelectorAll('[data-hours-end]').forEach(function(element){
            element.type = 'time'
          })
          x.querySelector('[data-hours-minutes-error]').classList.remove('hidden')
          event.preventDefault();
          return false;
        }
      })
    } else if (event.detail.step == 'dates'){
      document.querySelectorAll('[data-hours-start]').forEach(function(element){
        element.type = 'time'
      })

      document.querySelectorAll('[data-hours-end]').forEach(function(element){
        element.type = 'time'
      })
      return true;
    } else {
      document.querySelectorAll('[data-hours-start]').forEach(function(element){
        element.type = 'text'
      })

      document.querySelectorAll('[data-hours-end]').forEach(function(element){
        element.type = 'text'
      })
      return true;
    }
  }

  function showAttendees(){
    console.log('showing attendees')
    var checkboxes = Array.from(document.querySelectorAll('[data-person-check-box] input[type="checkbox"]'));
    console.log(checkboxes)
    var checked = checkboxes.filter(x => x.checked == true).length - 1;

    var checkboxesGuests = Array.from(document.querySelectorAll('[data-attribute-name="email"]'));
    checkboxesGuests.forEach(function(element){
      var input = element.querySelector('input');
      if (input.value && input.value != ''){
        checked += 1;
      }
    })
    document.querySelector('[data-attendees]').innerHTML = checked + ' gasten geselecteerd';
  }

  function onDagChange(selectedDatum){
    var year = selectedDatum.getFullYear();
    var month = (selectedDatum.getMonth() + 1 < 10 ? '0' + (selectedDatum.getMonth() + 1) : (selectedDatum.getMonth() + 1));
    var day = (selectedDatum.getDate() < 10 ? '0' + selectedDatum.getDate() : selectedDatum.getDate());

    var datestring = year + '-' + month + '-' + day;
    var datestringLocale = day + '-' + month + '-' + year;

    var element = document.querySelector('[data-appointment="' + datestring + '"]')

    if (element){
      element.classList.remove('hidden')
    }
    else {
      window.cloner.addDate(datestring)
      initAddTimes();
    }

    linkTimes();
    linkRemoveDates()
    linkRemoveTimes()
    showDays();
  }

  function showDays(){
    var visibleDateElements = document.querySelectorAll('[data-appointment]:not(.hidden)')
    days = visibleDateElements.length
    var datesElement = document.querySelector('[data-dates]');
    datesElement.innerHTML = days + ' ' + datesElement.dataset.dates
  }

  function addTime(event){
    var referenceNode = event.currentTarget.closest('[data-appointment]')

    const appointmentTimeElement = referenceNode.querySelector('[data-time].hidden')
    if (appointmentTimeElement){
      appointmentTimeElement.classList.remove('hidden')
      const dateElement = appointmentTimeElement.parentElement.querySelector('[data-date-field]')
      const dateString = dateElement.dataset.date
      dateElement.value = dateString
    } else {
      window.cloner.addTime(referenceNode)
    }

    linkTimes();
    linkRemoveTimes()
  }

  function addGuest(event){
    var referenceNode = document.querySelector('[data-guest-template]')
    window.cloner.addGuest(referenceNode)
  }

  function linkTimes(){
    document.querySelectorAll('[data-time]').forEach(x => {
      x.querySelector('[data-hours-start]').addEventListener('change', y => {
        try{
          const parts = y.currentTarget.value.split(':')
          y.currentTarget.classList.add('active')
          let minutes = parseInt(parts[1]);
          let hours = parseInt(parts[0]);
          minutes = minutes + 30;
          if (minutes >= 60){
            minutes = minutes - 60;
            hours += 1;
          }

          var hoursEnd = x.querySelector('[data-hours-end]');
          hoursEnd.value = "" + hours + ":" + (minutes < 10 ? ("0" + minutes) : minutes)
          hoursEnd.classList.add('active')
        } catch(ex) {
          console.error(ex);
        }
      })

      x.querySelector('[data-hours-end]').addEventListener('change', y => {
        y.currentTarget.classList.add('active')
      });
    })
  }

  function linkRemoveDates() {
    document.querySelectorAll('[data-delete-date]').forEach(x => {
      x.removeEventListener('click', handleRemoveDate);
      x.addEventListener('click', handleRemoveDate);
    });
  }

  function handleRemoveDate(event){
    const appointmentDateElement = event.currentTarget.closest('[data-appointment]')
    const appointmentDate = appointmentDateElement.dataset.appointment;
    appointmentDateElement.querySelectorAll('[data-date-field]').forEach(x => x.value = '')

    calendar.removeDate(appointmentDate)
    appointmentDateElement.classList.add('hidden')
  }

  function linkRemoveTimes(){
      document.querySelectorAll('[data-delete-time]').forEach(x => {
      x.removeEventListener('click', handleRemoveTime);
      x.addEventListener('click', handleRemoveTime);
    });
  }

  function handleRemoveTime(event){
    const appointmentTimeElement = event.currentTarget.closest('[data-time]')
    appointmentTimeElement.querySelectorAll('[data-date-field]').forEach(x => x.value = '')

    appointmentTimeElement.classList.add('hidden')
    initAddTimes();
  }

  function initAddTimes(){
    document.querySelectorAll('[data-add-time]').forEach(x => {
      x.removeEventListener('click', addTime);
      x.addEventListener('click', addTime);
    })
  }

  function initAddGuests(){
    document.querySelectorAll('[data-add-guest]').forEach(x => {
      x.removeEventListener('click', addGuest);
      x.addEventListener('click', addGuest);
    })
  }

  return {
    init: init,
    showAttendees: showAttendees,
    initListeners: initListeners,
    onDagChange: onDagChange
  }
})();

window.calendarHelper = calendarHelper;
