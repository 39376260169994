import CGMDevice from '../root/device'

(function(){
  window.addEventListener("load", function(event) {
    document.removeEventListener('custom::ajax:success', init)
    document.addEventListener('custom::ajax:success', init)
    init();
  });

  function init() {
    var placeholderElement = document.querySelector('#placeholder');
    if (!placeholderElement) { return; }

    if (document.addEventListener) {
      document.addEventListener('click', interceptClickEvent);
    } else if (document.attachEvent) {
      document.attachEvent('onclick', interceptClickEvent);
    }
  }

  function interceptClickEvent(event){
    var target = event.target || event.srcElement;
    if (target.tagName === 'A' || event.target.closest('a')) {
      var aElement = target.tagName === 'A' ? target.tagName : event.target.closest('a')
      if (aElement && aElement.dataset && aElement.dataset.showPlaceholder) {
        showPlaceholder();
      }
    }
  }

  function showPlaceholder(){
    if (!CGMDevice.isMobile()) { return; }

    var placeholderElement = document.querySelector('#placeholder');
    if (!placeholderElement) { return; }

    if (placeholderElement) {
      var content = placeholderElement.parentElement.querySelector('[data-content]')
      if (content) {
        content.style.display = 'none'
      }
      placeholderElement.style.display = 'block'
    }
  }
})();