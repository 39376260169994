import CGMStorage from "../root/storage"

(function(){
  window.addEventListener("load", function(event) {
    init();
  });

  function init(){
    var collapseTrigger = document.querySelector('[data-bs-collapse]')
    if (!collapseTrigger) { return; }

    addTriggers(collapseTrigger);

    var showingCollapsedSidebar = CGMStorage.getItem('berichten_sidebar_collapsed');
    if (showingCollapsedSidebar && showingCollapsedSidebar == 'true'){
      collapseElements(collapseTrigger);
    } else {
      showElements(collapseTrigger);
    }
  }

  function addTriggers(collapseTrigger){
    collapseTrigger.style.cursor = 'pointer'
    collapseTrigger.addEventListener('click', onCollapseTriggerClicked);
  }

  function onCollapseTriggerClicked(event){
    var element = event.currentTarget;

    if (element.classList.contains('collapsed')){
      showElements(element);
    } else {
      collapseElements(element);
    }
  }

  function collapseElements(element){
    var target = element.dataset.bsCollapse;
    var collapseElement = document.querySelector(target);
    if (!collapseElement) { return; }
    collapseElement.style.display = 'block'
    collapseElement.classList.add('collapsed');
    element.classList.add('collapsed')
    CGMStorage.setItem('berichten_sidebar_collapsed', true)
  }

  function showElements(element){
    var target = element.dataset.bsCollapse;
    var collapseElement = document.querySelector(target);
    if (!collapseElement) { return; }

    collapseElement.style.display = 'block'
    collapseElement.classList.remove('collapsed');
    element.classList.remove('collapsed')
    CGMStorage.setItem('berichten_sidebar_collapsed', false)
  }
})();