(function(){
  window.addEventListener("load", function(event) {
    document.removeEventListener('custom::ajax:success', init)
    document.addEventListener('custom::ajax:success', init)
    init();
  });
  
  function init(){
    const elements = document.querySelectorAll('[data-preview-toggle]')

    elements.forEach(function (element) {
      element.removeEventListener('click', togglePreview)
      element.addEventListener('click', togglePreview)
    })
  }
  
  function togglePreview(event){
    event.preventDefault();
    event.stopPropagation();
    
    var target = event.currentTarget;
    if (target.dataset.previewTarget){
      var toToggleElement = document.querySelector('[data-preview="' + target.dataset.previewTarget + '"]')
      if (toToggleElement){
        toToggleElement.classList.toggle('visible')
      }
      var iframe = toToggleElement.querySelector('iframe');
  
      if (iframe && iframe.dataset.src){
        iframe.src = iframe.dataset.src
      }

      var object = toToggleElement.querySelector('object');
      if (object && object.dataset.src){
        window.setTimeout(function() {
          object.data = object.dataset.src
        }, 100);
      }
      
      var spinner = toToggleElement.querySelector('.spinner-preview');
      var spinnerTimout = parseInt(spinner.dataset.timout);
      if (spinner){
        if (spinnerTimout == 1){
          spinner.style.display = 'none'
          var spinnerErrorElement = toToggleElement.querySelector('.spinner-error');
          if (spinnerErrorElement) {
            spinnerErrorElement.style.display = 'block'
          }
        } else {
          window.setTimeout(function() {
            if (spinner) {
              spinner.style.display = 'none'
              var spinnerErrorElement = toToggleElement.querySelector('.spinner-error');
              if (spinnerErrorElement) {
                spinnerErrorElement.style.display = 'block'
              }
            }
          }, spinnerTimout);
        }
      }
    }
  }
})();