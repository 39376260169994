window.addEventListener('resize', resizeJitsiRoom);

document.addEventListener("click", function(event) {
  /* Button clicked to copy URL from given ID into clipboard */

  let jitsi_itv_session;

  switch (event.target.id) {
    case 'jitsi-start':
      {
      var jitsi_btn= event.target;
      jitsi_toggle('show');

      const domain = jitsi_btn.dataset.jitsiDomain;
      const options = {
        roomName: jitsi_btn.dataset.jitsiRoom,
        width: '100%',
        height: initialJitsiRoomHeigth(),
        parentNode: document.querySelector('#jitsi-room'),
        jwt: jitsi_btn.dataset.jitsiToken,
        userInfo: {
          email: jitsi_btn.dataset.jitsiEmail,
          displayName: jitsi_btn.dataset.jitsiDisplayName
        },
        configOverwrite: {
          subject: jitsi_btn.dataset.jitsiSubject,
          fileRecordingsEnabled: false,
          enableInsecureRoomNameWarning: true,
          analytics: analyticsConfig()
        },
        interfaceConfigOverwrite: {
          // BRAND_WATERMARK_LINK: 'assets/zv/cgm_logo_met_naam.png',                   # No effect?
          // DEFAULT_LOGO_URL: 'assets/zv/cgm_logo_met_naam.png',                       # No effect?
          // DEFAULT_WELCOME_PAGE_LOGO_URL: 'assets/zv/cgm_logo_met_naam.png',          # No effect?
          // JITSI_WATERMARK_LINK: 'https://www.ozoverbindzorg.nl',                     # No effect?
          // SHOW_BRAND_WATERMARK: true,                                                # No effect?
          // SHOW_JITSI_WATERMARK: false,                                               # No effect?
          // SHOW_POWERED_BY: true,
          DEFAULT_REMOTE_DISPLAY_NAME: 'Deelnemer',
          MOBILE_APP_PROMO: false
        }
      }
      window.jitsi_api = new JitsiMeetExternalAPI(domain, options);

      // Keep the session alive every minute
      jitsi_itv_session= setInterval(function() {
        window.zv_session_timeout_is_busy= true;
      }, 60000);      // 1 minute

      /* Set a password for this meeting. Everybody requires the password, not only guests that are invited from wihtin the session */
      window.jitsi_api.addEventListener('participantRoleChanged', function (event) {
        if(event.role === 'moderator') {
          // jitsi_api.executeCommand('toggleLobby', true);
          // jitsi_api.executeCommand('password', '1234EfGh');
        }
      });

      // When the Red Jitsi button 'Hangup' is clicked, then execte
      window.jitsi_api.addEventListener('readyToClose', function (event) {
        jitsi_cleanup(jitsi_itv_session);
      });

      event.preventDefault();
      event.stopPropagation();
      break;
    }
    case 'jitsi-close-btn':
      // Clear the interval keeping the session alive
      window.jitsi_api.executeCommand('hangup');
      setTimeout(jitsi_cleanup, 2000, jitsi_itv_session);

      event.preventDefault();
      event.stopPropagation();
      break;
  }
});


/* Dispose Jitsi instance, reset session interval and show normal window again */
function jitsi_cleanup(jitsi_itv_session) {
  clearInterval(jitsi_itv_session);
  window.jitsi_api.dispose();
  jitsi_toggle('hide');
}


/* @param status [String] show/hide */
function jitsi_toggle(status) {
  let jitsi_room= document.getElementById('jitsi-room');
  let form= document.getElementById('runtime_observation_form');
  let jitsi_btn_bar= document.getElementById('jitsi-btn-bar');
  let jitsiWrapperElement = document.querySelector('.jitsi-wrapper');

  if (status=='show') {
    jitsiWrapperElement.classList.add('active')
    jitsi_btn_bar.classList.remove('hide');
    if (form) form.classList.add('hide');
  } else {
    // Hide Jitsi
    jitsi_room.innerHTML= '';
    jitsiWrapperElement.classList.remove('active')
    jitsi_btn_bar.classList.add('hide');
    if (form) form.classList.remove('hide');
  }
}

/* Resize Jitsi window heigth. Width is automatically taken care off */
function resizeJitsiRoom() {
  const jitsi_iframe= document.querySelector('#jitsi-room > iframe')
  if (jitsi_iframe) {
    jitsi_iframe.style['height']= initialJitsiRoomHeigth() + 'px';
  }
}

/* The initial heigth of the Jitsi window */
function initialJitsiRoomHeigth() {
  return Math.max( 300, window.innerHeight - 300);
}

/* Build the Analytics config options. Return {} object */
/* Google Analytics is replaced by Matomo Analytics; find new solution */
function analyticsConfig() {
  let config= {}
  // if (analytics_id= document.querySelectorAll('meta[name="ga_id"]')[0]) {
  //   config= {
  //     googleAnalyticsTrackingId: analytics_id,
  //     scriptURLs: [
  //       "libs/analytics-ga.min.js",     // google-analytics
  //     ]
  //   }
  // }
  return config;
}