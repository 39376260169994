window.addEventListener('load', function(event) {

  $(document).on("ajax:error", function(event) {
    var detail= event.originalEvent.detail;
    // var msg= event.originalEvent.detail[0];
    // alert(msg);  // Alert the message
    // if (detail[1]=='Unauthorized') location.reload();     // Session timeout, other statusses Forbidden
    location.reload();
    event.preventDefault();
    return false;
  });

});