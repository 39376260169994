window.addEventListener('load', function(event) {

  /* History changes act like an accordion. */
  $("#conditions_of_use .accordionable h3").click(function(event) {
    // Close all H3+DIV elements
    $("#conditions_of_use .accordionable h3+div").addClass('hide');
    // But show this one
    $(this).next().removeClass('hide');
  });

  /* Hide all H3+DIV elements on page refresh except for first one */
  $("#conditions_of_use .accordionable h3:not(:first-of-type)+div").addClass('hide');
})