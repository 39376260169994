/* Function will show/hide password when main (left) mouse button pressed */
var eyeButtonFunction= function (event) {
  if (event.target.classList.contains('eye_button')) {
    if (event.type=='mousedown' || event.type=='mouseup') {
      // event.button==0 means main mouse button
      if (event.button==0) togglePassword(event.target.parentElement.previousElementSibling);
    } else if (event.type=='touchstart' || event.type=='touchend') {
      togglePassword(event.target.parentElement.previousElementSibling);
    } else if (event.type=='mouseout') {
      // Make sure input field is set to 'password' when leaving
      event.target.parentElement.previousElementSibling.type='password';
    }
  }
};

window.addEventListener("mousedown", eyeButtonFunction, false);
window.addEventListener("mouseup", eyeButtonFunction, false);
window.addEventListener("mouseout", eyeButtonFunction, false);
window.addEventListener("touchstart", eyeButtonFunction, false);
window.addEventListener("touchend", eyeButtonFunction, false);

/** Show password on mousedown
 * @param elem [Object] input element
 **/
function togglePassword(elem) {
  if (elem.value!='') {
    if (elem.type == 'text') {
      elem.type='password';
    } else {
      elem.type='text';
    }
    elem.focus();
  }
}


/* Show/Hide CAPSLOCK warning with password fields */
window.addEventListener("keyup", function(event) {

  if (event.target.nodeName=='INPUT' && event.target.attributes.type.nodeValue=='password') {
    let warning_elements= document.querySelectorAll('.capslock_warning');

    const warning_elm= warning_elements[0]
    if (warning_elm) {
      if (event.getModifierState("CapsLock")) {
        console.log('CAPSLOCK IS ON!');
        warning_elm.classList.remove('hide');
        warning_elm.innerText= 'Let op: uw CapsLock staat aan!';
      } else {
        // Hide all warnings on page
        for (var i = 0; i < warning_elements.length; i++) {
          warning_elements[i].classList.add('hide');
        }
      }
    }
  }
});