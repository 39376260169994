var tabs = (function(){
  function init() {
    document.removeEventListener('custom::ajax:success', initAjaxCallback)
    document.addEventListener('custom::ajax:success', initAjaxCallback)

    initializeTabs();
    initializeFilters();

    var activeTab = (document.querySelector('[data-tab-trigger].active') || document.querySelector('[data-tab-trigger]'));
    if (activeTab) {
      handleTab({currentTarget: activeTab});
    }
  }

  function initAjaxCallback(){
    initializeTabs();
    initializeFilters();
  }

  function initializeTabs(){
    var tabs = Array.from(document.querySelectorAll('[data-tab-trigger]'))
    tabs.forEach(function(tab){
      tab.removeEventListener('click', handleTab);
      tab.addEventListener('click', handleTab);
    })
  }

  function initializeFilters(){
    var filterTabs = Array.from(document.querySelectorAll('.tabs.has--filter'))
    filterTabs.forEach(function(tab){
      var filterTriggers = Array.from(tab.querySelectorAll('.filter-trigger'))
      filterTriggers.forEach(function(filterTrigger) {
        filterTrigger.removeEventListener('click', handleFilter);
        filterTrigger.addEventListener('click', handleFilter);
      });
    })
  }

  function handleTab(event) {
    var tabTrigger = event.currentTarget;
    var tabTriggers = Array.from(document.querySelectorAll('[data-tab-trigger]'));
    tabTriggers.forEach(function(otherTabTrigger){
      otherTabTrigger.classList.remove('active')
    });
    tabTrigger.classList.add('active')

    var tabs = Array.from(document.querySelectorAll('[data-tab]'));
    tabs.forEach(function(tab){
      tab.classList.remove('is--active')
      if (tab.id == "tab_" + tabTrigger.dataset.tab){
        tab.classList.add('is--active')
        initializeLazyLoads(tab)
      }
    })
  }

  function initializeLazyLoads(tab){
    const items = tab.querySelectorAll('[data-zv-load-url]');

    items.forEach( function (elm) {
      Rails.ajax({
        type: 'get',
        url: elm.dataset.zvLoadUrl,
        dataType: 'script',
        success: function(data) {
          elm.innerHTML= data.html;
          window.tb_initDocument(elm);
          var customEvent = new CustomEvent('custom::ajax:success', {
            detail: {
            }});
          document.dispatchEvent(customEvent);
        }
      })
    })
  }

  function handleFilter(event){
    var filterTrigger = event.currentTarget;
    var filterTab = filterTrigger.closest('.has--filter');
    var slideOut = filterTab.querySelector('.slide-out-filters')
    slideOut.classList.toggle('is--active')

    var radioFilters = Array.from(filterTab.querySelectorAll('a'));
    radioFilters.forEach(function(radioFilter){
      radioFilter.removeEventListener('click', toggleRadioFilter)
      radioFilter.addEventListener('click', toggleRadioFilter)
    })
  }

  function toggleRadioFilter(event){
    var filterTrigger = event.currentTarget;
    var filterGroup = filterTrigger.closest('.filter-group')
    var radioFilters = Array.from(filterGroup.querySelectorAll('input[type=radio]'));
    radioFilters.forEach(function(radioFilter){
      radioFilter.checked = false;
    });

    filterTrigger.querySelector('input[type=radio]').checked = true;

    var filterTab = filterTrigger.closest('.has--filter');
    var slideOut = filterTab.querySelector('.slide-out-filters')
    slideOut.classList.toggle('is--active')
  }

  return {
    init: init
  }
})();

window.addEventListener('load', function(event) {
  tabs.init();
});