window.addEventListener('load', function(event) {
  /* Sign in form: in special case that an import is busy and the application is closed for normal users
  * enable the Submit button when the username equals sysbeheer
  */
  var elm = document.querySelector('.login input[name="person[username]"][data-import-busy="true"]')
  if (elm) elm.addEventListener("blur", function(event) {
    const btn= document.querySelector('.login #sign_in_btn')
    if (btn) {
      btn.disabled= event.target.value!=='sysbeheer';
    }
  })
});



/***
 * Handle modal dialog form 'Forgotten username'
 **/
window.addEventListener("click", function(event) {

  switch(event.target.id) {
    case 'username_forgotten':
      // Show popup and set focus to email
      $('#forgotten_username_window').modal().on('shown.bs.modal', function () {
        const userEmailElm= document.querySelector('#forgotten_username_email')
        if (userEmailElm) userEmailElm.focus();
      })

      $("#forgotten_username_window").modal('show')

      event.stopPropagation();
      event.preventDefault();
      break;
  }
})

var handleForgottenUsernameForm= function (event) {

  if (event.target.id=='forgotten_username_form') {

    switch(event.type) {
      case 'ajax:success':
        if (event.detail[0].html) {
          alert(event.detail[0].html);
        }
        break;
      case 'ajax:error':
        alert("fout opgetreden: " + JSON.stringify(event.detail[0]) + "\nmsg: " + event.detail[0].responseText + "\nstatus: " + event.detail[0].statusText);
        break;
      case 'ajax:complete':
        document.querySelector('#forgotten_username_email').value='';
        $('#forgotten_username_window').modal('hide');
        break;
    }
    event.stopPropagation();
    event.preventDefault();
  }
}
window.addEventListener("ajax:success", handleForgottenUsernameForm, false);
window.addEventListener("ajax:error", handleForgottenUsernameForm, false);
window.addEventListener("ajax:complete", handleForgottenUsernameForm, false);


/***
 *  Handle TwoFactor Authentication dialog
 **/

/* Form submitted within modal popup */
window.addEventListener("ajax:success", function(event) {
  // PATCH /sessions/mfa_verification.js
  if (event.target.id=='twostep_verification_form') {
    update_twostep_verification_form(event, event.detail);
  }
})

/* Activates the two-step authentication popup if required. */
var twofactor_authentication_required = function(detail) {
  let data = detail[0];

  if (data.location=='popup') {
    const elm= document.querySelector('#js_popup')
    if (elm) {
      // Editable window:
      elm.innerHTML= data.html;
    }

    // After popup, set focus to first input field (token)
    $('#twostep_window').on('shown.bs.modal', function () {
      window.activateJS($('#twostep_window'));
      var customEvent = new CustomEvent('custom::ajax:success', {
        detail: {
        }
      });
      document.dispatchEvent(customEvent);
    }).modal('show');

    return true;
  }
}

window.twofactor_authentication_required = twofactor_authentication_required

/* Update twostep modal popup:
 * - with new window as new token requested or token invalid
 * - token correctly verified, hide popup and try to submit original form
 * Note the the originating form needs to have a class 'twostep_verifiable'
 */
var update_twostep_verification_form = function(event, detail) {
  var data = detail[0], status = detail[1], xhr = detail[2];
  // Correctly verified: hide popup and try to submit original form
  if (data.location=='submit') {
    $('#twostep_window, #timeout_modal').modal('hide');
    // if (form= document.querySelector('form.twostep_verifiable')) submit_remote_form(form, event);
    let tfa_pending_elm= document.querySelector('[data-tfa-pending]');
    if (tfa_pending_elm) {
      switch(tfa_pending_elm.tagName) {
        case 'A':
          tfa_pending_elm.click();
          break;
        case 'FORM':
          window.submit_remote_form(tfa_pending_elm, event);
          break
      }
      // Remove the pending state of the original HTML element (Form or button) that was submitted
      delete tfa_pending_elm.dataset.tfaPending;
    }

  } else {
    // Refresh window information
    const elm= document.querySelector('#mfa_form')
    if (elm) {
      // Editable window:
      elm.innerHTML= data.html;
      window.activateJS(elm);
    }
  }
}