import CGMDevice from '../root/device'

var FullScreenResize = (function(){
  function init(){
    if (CGMDevice.isMobile() || CGMDevice.isIpad()){
      return
    }

    document.removeEventListener('custom::ajax:success', initAjax)
    document.addEventListener('custom::ajax:success', initAjax)

    window.removeEventListener('resize', reInitElements)
    window.addEventListener('resize', reInitElements)

    initElements();
  }

  function initElements(){
    Array.from(document.querySelectorAll('[data-full-screen-resize]')).forEach(function(element){
      var toSubstract = pixelsOutOfView(element);
      var newHeight = element.offsetHeight - toSubstract;
      if (newHeight < 150) { newHeight = 150;}

      element.style.height = newHeight+ 'px'
    })
  }

  function reInitElements(){
    Array.from(document.querySelectorAll('[data-full-screen-resize]')).forEach(function(element){
      element.style.height = '500px'
    })
    window.setTimeout(function(x) {
      initElements()
    }, 100);
  }

  function initAjax(event){
    var target = event.detail.target;
    if (target && target.closest('[data-has-arrows]')) {
      initArrows();
    }

    initElements();
  }

  function pixelsOutOfView(element){
      // Get the relevant measurements and positions
      const viewportHeight = window.innerHeight;
      const scrollTop = window.scrollY;
      const elementOffsetTop = element.offsetTop;
      const elementHeight = element.offsetHeight

      // Calculate percentage of the element that's been seen
      const distance = (elementOffsetTop + elementHeight) - (scrollTop + viewportHeight);
      // debugger
      // Restrict the range to between 0 and 100
      return distance > -50 ? distance + 50 : 0
  }

  function getAbsoluteHeight(el) {
    // Get the DOM Node if you pass in a string
    el = (typeof el === 'string') ? document.querySelector(el) : el;

    var styles = window.getComputedStyle(el);
    var margin = parseFloat(styles['marginTop']) +
      parseFloat(styles['marginBottom']);

    return Math.ceil(el.offsetHeight + margin);
  }

  function initArrows(){
    var arrowRight = document.querySelector('.arrow-right')
    var arrowLeft = document.querySelector('.arrow-left')
    if (!arrowLeft || !arrowRight) {return;}

    var containerElement = arrowRight.closest('[data-has-arrows]').querySelector('.person-list.horizontal');
    if (!containerElement) { return; }

    if (containerElement.scrollWidth > containerElement.clientWidth) {
      arrowRight.classList.remove('hidden')
      // arrowLeft.classList.remove('hidden') we start with scroll position = 0

      arrowRight.removeEventListener('click', scrollList)
      arrowRight.addEventListener('click', scrollList)
      arrowLeft.removeEventListener('click', scrollList)
      arrowLeft.addEventListener('click', scrollList)

      containerElement.removeEventListener('scroll', handleArrowScroll, { passive: true });
      containerElement.addEventListener('scroll', handleArrowScroll, { passive: true });
    }
  }

  function scrollList(event){
    var target = event.currentTarget;
    var containerElement = target.closest('[data-has-arrows]').querySelector('.person-list.horizontal');

    var personElement = containerElement.querySelector('.person-list-item')
    var personElementWidth = personElement.clientWidth;
    personElementWidth += 24; //padding

    if (target.classList.contains('arrow-right')){
      target.closest('[data-has-arrows]').querySelector('.person-list.horizontal').scrollLeft += personElementWidth;
    } else {
      target.closest('[data-has-arrows]').querySelector('.person-list.horizontal').scrollLeft -= personElementWidth;
    }
  }

  function handleArrowScroll(event){
    var containerElement = event.currentTarget;
    var arrowRight = document.querySelector('.arrow-right')
    var arrowLeft = document.querySelector('.arrow-left')
    if (!arrowLeft || !arrowRight) {return;}

    arrowLeft.classList.remove('hidden')
    arrowRight.classList.remove('hidden')

    if (containerElement.scrollLeft < 10){
      arrowLeft.classList.add('hidden')
    }
    console.log(containerElement.scrollLeft + containerElement.clientWidth)
    console.log(containerElement.scrollWidth)
    if (containerElement.scrollLeft + containerElement.clientWidth > containerElement.scrollWidth - 30) {
      arrowRight.classList.add('hidden')
    }
  }

  return {
    init: init,
    percentageInView: pixelsOutOfView
  }
})();

window.addEventListener('load', function(event) {
  FullScreenResize.init();
  window.FullScreenResize = FullScreenResize
});