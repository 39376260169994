(function(){
  document.addEventListener('custom::ajax:success', init)

  window.addEventListener("ajax:success", init);
  window.addEventListener("ajax:error", init);
  window.addEventListener("ajax:complete", init);
  window.addEventListener("invalid", init);

  window.addEventListener("load", function(event) {
    init()
  });
    
  
  function init(){
    var elements = document.querySelectorAll('button[type="submit"]');
    
    Array.from(elements).forEach(function (element) {
      // disableWith is handled by rails ujs
      if (!element.dataset.disableWith) {
        element.dataset.disableWith = '...'
      }
    });
  }
})();