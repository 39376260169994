
var cloner = (function(){
  function addTime(referenceNode){
    const date = referenceNode.dataset.appointment;
    // const timeNode = referenceNode.querySelector('.date-time');
    var timeNodes = referenceNode.querySelectorAll('.date-time');
    var timeNode = timeNodes[timeNodes.length- 1];

    let newElement = timeNode.cloneNode(true);
    timeNode.closest('.date-times').appendChild(newElement);

    const nodes = referenceNode.querySelectorAll('.date-time');
    newElement = nodes[nodes.length- 1];
    const randomId = Date.now();

    newElement.querySelectorAll('input').forEach(element => {
      const newName = element.name.replace(/[0-9]+(?!.*[0-9])/, randomId);
      const newId = element.id.replace(/[0-9]+(?!.*[0-9])/, randomId);
      if (element.dataset.dateField == '1') {
        element.value = date;
      } else {
        // element.value = ''
      }

      element.name = newName;
      element.id = newId;
    })

    newElement.querySelectorAll('label').forEach(element => {
      const newFor = element.getAttribute('for').replace(/[0-9]+(?!.*[0-9])/, randomId);
      element.setAttribute('for', newFor);
    })
  }

  function addGuest(referenceNode){
    const date = referenceNode.dataset.appointment;

    let newElement = referenceNode.nodeName == 'TEMPLATE' ? referenceNode.content.cloneNode(true) : referenceNode.cloneNode(true);

    referenceNode.closest('[data-guests]').appendChild(newElement);

    const nodes = referenceNode.closest('[data-guests]').querySelectorAll('[data-guest]');
    newElement = nodes[nodes.length- 1];
    const randomId = Date.now();

    newElement.querySelectorAll('input').forEach(element => {
      const newName = element.name.replace(/[0-9]+(?!.*[0-9])/, randomId);
      const newId = element.id.replace(/[0-9]+(?!.*[0-9])/, randomId);
      if (element.dataset.dateField == '1') {
        element.value = date;
      } else {
        element.value = ''
      }

      element.name = newName;
      element.id = newId;
    })

    newElement.querySelectorAll('label').forEach(element => {
      const newFor = element.getAttribute('for').replace(/[0-9]+(?!.*[0-9])/, randomId);
      element.setAttribute('for', newFor);
    })
  }

  function addDate(date) {
    const referenceNode = document.querySelector('[data-appointment-template]');

    const existing = document.querySelector('[data-appointment="' + date + '"]')
    if (existing) { return; }

    let newElement = referenceNode.nodeName == 'TEMPLATE' ? referenceNode.content.cloneNode(true) : referenceNode.cloneNode(true);
    document.querySelector('[data-appointments]').appendChild(newElement);

    const nodes = document.querySelector('[data-appointments]').querySelectorAll('[data-appointment]');
    newElement = nodes[nodes.length- 1];
    newElement.dataset.appointment = date

    const randomId = Date.now();

    newElement.querySelectorAll('input').forEach(element => {
      const newName = element.name.replace(/[0-9]+(?!.*[0-9])/, randomId);
      const newId = element.id.replace(/[0-9]+(?!.*[0-9])/, randomId);
      if (element.dataset.dateField == '1') {
        element.dataset.date = date;
        element.value = date;
      } else {
        element.value = ''
      }

      element.name = newName;
      element.id = newId;
    })

    newElement.querySelectorAll('[data-date-display]').forEach(element => {
      const parts = date.split('-');
      element.innerText = parts[2] + '/' + parts[1] + "/" + parts[0];
    })

    newElement.querySelectorAll('label').forEach(element => {
      const newFor = element.getAttribute('for').replace(/[0-9]+(?!.*[0-9])/, randomId);
      element.setAttribute('for', newFor);
    })

    newElement.classList.remove('hidden')
  }

  return {
    addDate: addDate,
    addTime: addTime,
    addGuest: addGuest
  }
})()

window.cloner = cloner