export default class Calendar {
  constructor(jaar, maand, dag, callback, dagen, element, disablePastDays = false, grayOutPastDays = false, disableDaysOfOtherMonths = false, hideDaysOfOtherMonth = false, isDoubleCalendar = false) {
    this.calenderElement = document.getElementById(element);

    if (jaar && maand && dag) {
      this.referenceDay = new Date(jaar, maand - 1, dag);
    } else {
      this.referenceDay = new Date();
    }

    this.callbackRef = callback;
    this.currentMonth = this.referenceDay.getMonth();
    this.currentYear = this.referenceDay.getFullYear();
    this.months = ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"];
    this.days = ["M", "D", "W", "D", "V", "Z", "Z"]
    this.highlightedDays = JSON.parse(dagen);
    this.disablePastDaysRef = disablePastDays;
    this.grayOutPastDaysRef = grayOutPastDays;
    this.disableDaysOfOtherMonthsRef = disableDaysOfOtherMonths;
    this.hideDaysOfOtherMonthRef = hideDaysOfOtherMonth;

    this.isDoubleCalendarRef = isDoubleCalendar;
    this.cells = []
    this.nextCalendarRef;
    this.addHighlightToDays = false;
    this.previousCalendarRef

    this.showCalendar(this.currentMonth, this.currentYear);

    this.highLightInitialCells()
  }

  toggleAddHighlightsTodays(){
    this.addHighlightToDays = !this.addHighlightToDays;
  }

  addCallback(callback){

  }

  addOtherCalendarRef(otherCalendar){
    this.otherCalendarRef = otherCalendar
    this.isDoubleCalendarRef = true
  }

  showCalendar(month, year) {
    this.currentYear = year;
    this.currentMonth = month;
    if (!this.calenderElement) { return; }

    this.calenderElement.innerHTML = '';
    var thead = document.createElement('thead')
    this.calenderElement.appendChild(thead)

    this.addMonthYear(this.calenderElement, thead, month, year)
    this.addDayHeaders(thead)
    this.addDays(this.calenderElement, month, year)

    this.calenderElement.querySelectorAll('[data-date]').forEach(x => {
      x.addEventListener('click', xx => {
        var date = new Date(xx.currentTarget.dataset.date)
        event.preventDefault();
        event.stopPropagation();

        if (this.addHighlightToDays){
          this.addClickedCellToHighlightedDays(date, x);
        }

        if (this.callbackRef) {
          this.callbackRef(date);
        }
      })
    })
  }

  handleNavigation(calenderElement, prevMonthElement, nextMonthElement){
    if (!calenderElement.dataset.hidePreviousMonth) {
      prevMonthElement.innerText = "keyboard_arrow_left";

      prevMonthElement.addEventListener('click',  event => {
        if (this.isDoubleCalendarRef) {

          console.log(this.currentMonth)
          console.log(this.currentYear)

          this.currentYear = (this.currentMonth == 0 || this.currentMonth == 1) ? this.currentYear - 1 : this.currentYear;
          this.currentMonth = (this.currentMonth == 0) ? 10 : (this.currentMonth == 1) ? 11 : (this.currentMonth - 2);
          this.showCalendar(this.currentMonth, this.currentYear);

          if (this.otherCalendarRef) {
            this.otherCalendarRef.showCalendar((this.currentMonth == 11 ? 0 : this.currentMonth + 1), this.currentYear)
          }

        } else {
          this.currentYear = (this.currentMonth === 0) ? this.currentYear - 1 : this.currentYear;
          this.currentMonth = (this.currentMonth === 0) ? 10 : (this.currentMonth - 1);
          this.showCalendar(this.currentMonth, this.currentYear);
        }
        event.preventDefault();
        event.stopPropagation();
      })
    }

    if (!calenderElement.dataset.hideNextMonth) {
      nextMonthElement.innerText = "keyboard_arrow_right";

      nextMonthElement.addEventListener('click', event => {
        if (this.isDoubleCalendarRef) {

          console.log(this.currentMonth)
          console.log(this.currentYear)

          this.currentYear = (this.currentMonth === 11) ? this.currentYear + 1 : this.currentYear;
          this.currentMonth = (this.currentMonth + 2) % 12;
          this.showCalendar(this.currentMonth, this.currentYear);

          if (this.otherCalendarRef) {
            this.otherCalendarRef.showCalendar(((this.currentMonth) <= 0 ? 11 : this.currentMonth -1), this.currentYear)
          }

        } else {
          this.currentYear = (this.currentMonth === 11) ? this.currentYear + 1 : this.currentYear;
          this.currentMonth = (this.currentMonth + 1) % 12;
          this.showCalendar(this.currentMonth, this.currentYear);
        }
        event.preventDefault();
        event.stopPropagation();
      })
    }
  }

  addMonthYear(calenderElement, thead, month, year) {
    var tr = document.createElement('div')
    tr.classList.add('cal-row')
    thead.appendChild(tr);

    var dayMonthElement = document.createElement('div')
    dayMonthElement.classList.add('year-month')
    tr.appendChild(dayMonthElement);

    var prevMonthElement = document.createElement('span')
    dayMonthElement.appendChild(prevMonthElement);

    var selectMonthElement = document.createElement('h3')
    selectMonthElement.innerText = this.months[month];
    dayMonthElement.appendChild(selectMonthElement);

    var selectYearElement = document.createElement('h3')
    selectYearElement.innerText = year;
    dayMonthElement.appendChild(selectYearElement);

    var nextMonthElement = document.createElement('span')
    dayMonthElement.appendChild(nextMonthElement);

    this.handleNavigation(calenderElement, prevMonthElement, nextMonthElement);
  }

  addDayHeaders(thead) {
    var tr = document.createElement('div')
    tr.classList.add('cal-row')
    thead.appendChild(tr)

    var cell = document.createElement("div");
    cell.innerText = ''
    cell.classList.add('week-number')
    tr.appendChild(cell)

    this.days.forEach(function (day) {
      var cell = document.createElement("div");
      var cellSpan = document.createElement("span")
      var cellText = document.createTextNode(day);
      cellSpan.appendChild(cellText);
      cell.appendChild(cellSpan);
      tr.appendChild(cell);
    })
  }

  addDays(calenderElement, month, year) {
      var tbody = document.createElement('div')
      tbody.classList.add('calendar-body')
      calenderElement.appendChild(tbody)

      var firstDayOfMonth = (new Date(year, month));
      var lastDayOfMonth = new Date(year, month + 1).addDays(-1)
      var firstMondayThisMonth = new Date(year, month).monday();

      var date = firstMondayThisMonth;
      for (var row = 0; row < 6; row++) {
          var rowElement = document.createElement("div");
          rowElement.classList.add('cal-row')

          var cell = document.createElement("div");
          var cellTextSpan = document.createElement("span");
          var cellText = document.createTextNode(date.weekNumber());
          cellTextSpan.appendChild(cellText);
          cell.appendChild(cellTextSpan);
          cell.classList.add('week-number')
          rowElement.appendChild(cell);
          var todayDate = new Date();

          var isMonthsInThePast = ((date.getMonth() < todayDate.getMonth()
              && date.getFullYear() == todayDate.getFullYear()))
          var isYearsInThePast = date.getFullYear() < todayDate.getFullYear();

          for (var day = 0; day < 7; day++) {
              var isDaysInThePast = (date.getDate() < todayDate.getDate()
                  && date.getMonth() === todayDate.getMonth()
                  && date.getFullYear() === todayDate.getFullYear());
              cell = document.createElement("div");
              cellText = document.createTextNode(date.getDate());

              if (date < firstDayOfMonth || date > lastDayOfMonth) {
                  if (this.hideDaysOfOtherMonthRef) {
                      cell.style.opacity = '0.1';
                      cell.dataset.hidden = '1'
                  } else {
                      cell.style.opacity = '0.5';
                  }
                  if (this.disableDaysOfOtherMonthsRef) {
                      cell.style.pointerEvents = 'none';
                  }
              }
              else if (isDaysInThePast || isMonthsInThePast || isYearsInThePast) {
                  if (this.grayOutPastDaysRef) {
                      if (date.setHours(0, 0, 0, 0) == todayDate.setHours(0, 0, 0, 0)) {
                        //nothing
                      }
                      if (date < todayDate) {
                          cell.style.opacity = '0.1';
                      }
                  }
                  if (this.disablePastDaysRef) {
                      cell.style.pointerEvents = 'none';
                  }

                  this.checkHighlichtCell(date, cell);
              }
              else {
                this.checkHighlichtCell(date, cell);
              }

              this.checkHighLightCurrentDay(date, cell)

              var currentDay = new Date();
              var daysBetween = Math.round((date - currentDay.setHours(0, 0, 0, 0)) / (1000 * 60 * 60 * 24));

              var daysBetweenStr = daysBetween.toString();
              cell.id = 'current-day' + (daysBetween != 0 ? daysBetweenStr : '');

              cellTextSpan = document.createElement("span");
              cellTextSpan.appendChild(cellText);

              cell.appendChild(cellTextSpan);

              var cellCountSpan = document.createElement("span");
              cellCountSpan.className = 'cell-count'
              cell.appendChild(cellCountSpan);

              rowElement.appendChild(cell);
              cell.dataset.date = date
              const month = date.getMonth() + 1;
              const shortDateString = "" + date.getFullYear() + "-" + (month < 10 ? ("0" + month) : month) + "-" + ( date.getDate() < 10 ? ("0" + date.getDate()) : date.getDate());

              cell.dataset.dateString = shortDateString;

              this.cells.push(cell)
              date = date.addDays(1);
          }
          tbody.appendChild(rowElement); // appending each row into calendar body.
      }

      this.highlightWeek()
  }

  checkHighLightCurrentDay(date, cell) {
    var currentDay = new Date();
    if (currentDay.getDate() == date.getDate() && currentDay.getMonth() == date.getMonth() && currentDay.getFullYear() == date.getFullYear()) {
      this.highlightCurrentDay(cell);
    }
  }

  highlightWeek() {
    this.calenderElement.querySelector('tr', function (element) {
      element.classList.remove('selected-week')
    })

    if (this.calenderElement.dataset.showCurrentWeek == '0') { return; }

    this.cells.forEach(function (cell) {
      if (cell.classList.contains('selected-day')) {
        cell.parentElement.classList.add('selected-week');
      }
    });
  }

  checkHighlichtCell(date, cell){
    var dateString = date.getFullYear() + "-" + ((date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)) + '-' + (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());

    const highlightedDay = this.highlightedDays.find(x => x.date == dateString);
    if (highlightedDay) {
      this.highLightCell(cell, highlightedDay);
    }
  }

  addClickedCellToHighlightedDays(date, cell){
    if (!this.addHighlightToDays) { return; }

    var dateString = date.getFullYear() + "-" + ((date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)) + '-' + (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());

    var index = this.highlightedDays.map(x => x.date).indexOf(dateString);
    if (index >= 0){
      // empty
    } else {
      this.highlightedDays.push({
        date: dateString,
        style: 'opaque'
      })
    }
    this.highLightInitialCells();
  }

  highLightCell(cell, day) {
    if (cell.dataset.hidden == '1') { return }
console.log(cell.dataset)
    cell.classList.add("highlighted-day");
    cell.classList.add(day.style)


    const cellCountElement = cell.querySelector('.cell-count')
    if (day.count && day.count > 1 && cellCountElement){
      cellCountElement.innerText = day.count
      cellCountElement.classList.add('active')
    } else if (cellCountElement){
      cellCountElement.classList.remove('active')
    }
  }

  unHighlightCell(cell){
    cell.classList.remove('highlighted-day');
    const cellCountElement = cell.querySelector('.cell-count')
    if (cellCountElement){
      cellCountElement.classList.remove('active')
      cellCountElement.innerText = ''
    }
  }

  highLightInitialCells() {
    this.cells.forEach((cell) => {
      this.unHighlightCell(cell);
    })

    this.highlightedDays.forEach(day => {
      const cell = this.calenderElement.querySelector('[data-date-string="' + day.date + '"]');
      if (cell){
        this.highLightCell(cell, day)
      }
    })
  }

  removeDate(date){
    const parts = date.split('-')
    const correctedDate = "" + parts[0] + '-' + parts[1] + '-' + parts[2];
    this.highlightedDays = this.highlightedDays.filter( x => x.date != correctedDate)
    this.highLightInitialCells()
  }

  highlightCurrentDay(cell) {
    this.cells.forEach(function (cell) {
      cell.classList.remove('current-day');
    })
    cell.classList.add("current-day");
  }
};

window.Calendar = Calendar;
