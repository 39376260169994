document.addEventListener("click", function(event) {

  /* Help button */
  $('#help-btn').click(function(event) {
    /* if help already expanded than close it again */
    if (document.getElementById('help') ) {
      collapse_help('#js_content');
      return false;
    }
  });

  // On click 'Tutorial' button(s)
  switch(event.target.id) {
    case 'close_help_button':
      $('#js_help').empty();
      event.preventDefault();
      event.stopPropagation();
      break;

    case 'close_window_btn':
      window.close();
      event.preventDefault();
      event.stopPropagation();
      break;

    case 'read_more_help':
      {
        // Hide current button
        event.target.classList.add('hide');
        // Show
        const read_less_help_btn= document.querySelector('#read_less_help')
        if (read_less_help_btn) {
          read_less_help_btn.classList.remove('hide')
        }
        const doc_description_elm= document.querySelector('#doc_description')
        if (doc_description_elm) {
          doc_description_elm.classList.remove('hide')
        }
        // In case called on a a{href} in stead of a button tag
        event.preventDefault();
        event.stopPropagation();
      }
      break;

    case 'read_less_help':
      {
        // Hide current button
        event.target.classList.add('hide');
        // Show
        const read_more_help_btn= document.querySelector('#read_more_help')
        if (read_more_help_btn) {
          read_more_help_btn.classList.remove('hide')
        }
        const doc_description_elm= document.querySelector('#doc_description')
        if (doc_description_elm) {
          doc_description_elm.classList.add('hide')
        }
        // In case called on a a{href} in stead of a button tag
        event.preventDefault();
        event.stopPropagation();
      }
      break;

    default:
      /* Close and (X) button clicked for help window */
      if (event.target.classList.contains('collapse_help')) {
        collapse_help('#js_content');
        event.preventDefault();
        event.stopPropagation();
      } else if (event.target.parentNode.classList.contains('collapse_help')) {
        collapse_help('#js_content');
        event.preventDefault();
        event.stopPropagation();
      }
  }

});


/* Help window: split screen by adding columns class for content part */
function expand_help(content_elm) {
  $(content_elm).addClass('col-lg-16');
}
function collapse_help(content_elm) {
  // $(this).tooltip('destroy');  Console error
  $(content_elm).removeClass('col-lg-16');
  $('#js_help').empty();
}

window.expand_help = expand_help
window.collapse_help = collapse_help
