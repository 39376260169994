/*
* Multi records are like nested attributes where we can show/hide a number of nested rows
* Used in phone contacts and irc addresses
*/
document.addEventListener("click", function(event) {

  var parentElm= event.target.dataset.onclickAddNestedItem
  if (parentElm) {
    // Find next row to unhide it
    const next_row= document.querySelector(parentElm + ' [data-row-nr].hide')
    if (next_row) {
      next_row.classList.remove('hide');

      // Remove current Add button and any tooltip
      $(event.target).tooltip('hide');
      event.target.classList.add('hide');

      // Be sure to have the Add button in the last visible row
      unhide_last_multi_record_add_btn(parentElm);
    }
    event.stopPropagation();
    event.preventDefault();
  }
});


function unhide_last_multi_record_add_btn(parentElm) {
  // Find Add button in last visible row and unhide it unless all rows are visible
  let total_rows_length= document.querySelectorAll(parentElm + ' [data-row-nr]').length;
  let visible_add_btns= document.querySelectorAll(parentElm + ' [data-row-nr]:not(.hide) button.add')
  if (visible_add_btns) {
    if (visible_add_btns.length < total_rows_length ) {
      let last_add_btn= visible_add_btns[visible_add_btns.length-1];

      // So should not be the very last row
      last_add_btn.classList.remove('hide');
    };
  }
}